import { Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { addOfferingPortfolioEnums, inputValidationError, validateResponse } from "../../../shared/enums/userManagementEnum";
import { iAddOfferingPortfolio } from "../../../shared/interfaces/userManagementInterface";
import CommonButton from "../../common/input/CommonButton";
import Label from "../../common/input/Label";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import LeadsDropdown from "../offering/LeadsDropdown";
import UsersDropdown from "../offering/UsersDropdown";
import "./AddOfferingPortfolio.css";

const { Title, Text } = Typography;

const AddOfferingPortfolio = ({
  addOfferingPortfolioModalOpen,
  setAddOPModalOpen,
  getOfferingPortfolioData,
}: iAddOfferingPortfolio) => {
  const [addOfferingPortfolioDetails, setAddOfferingPortfolioDetails] =
    useState({
      op_code: "",
      op_name: "",
      op_coo: "",
      op_coo_delegate: "",
      op_coo_id: "",
      op_coo_delegate_id: "",
      op_leader_user: ""
    });
  const [form] = Form.useForm();
  const [valueChanged, setValueChanged] = useState({
    op_code: false,
    op_name: false,
    op_coo: false,
    op_coo_delegate: false,
    op_leader_user: false
  });
  const [loading, setLoading] = useState(false);
  const [leadersData, setLeadersData] = useState<any>([]);
  const [error, setError] = useState("");
  const [validation, setValidation] = useState(false);
  const [OPNameMenuItems, setOPNameMenuItems] = useState([]);
  const [OPItemsDetails, setOPItemDetails] = useState<any>([]);
  const [validateLeadandCoo, setValidateLeadandCoo] = useState(false);

  const options = {
    headers: {}
  }
  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.preferred,
        }));
        setLeadersData(datas);
      });
  };

  useEffect(() => {
		if (
			addOfferingPortfolioDetails?.op_code != "" &&
			addOfferingPortfolioDetails?.op_name.trim() != "" &&
      addOfferingPortfolioDetails?.op_name.trim().length >= 2 &&
			addOfferingPortfolioDetails.op_coo != "" && 
      addOfferingPortfolioDetails.op_leader_user != ""
		) {
			setValidation(true);
		} else {
			setValidation(false);
		}
	}, [valueChanged]);

  useEffect(() => {
    callApi();
  }, [addOfferingPortfolioDetails]);

  const updateOfferingPortfolioDetails = async () => {
    setLoading(true);
    const lead = leadersData.find((item: any) => {
      return item.text == addOfferingPortfolioDetails.op_leader_user;
    });

    // let delegate: any = "";
    // const val = leadersData?.find((item: any) => {
    //   return item.text == addOfferingPortfolioDetails?.op_coo_delegate;
    // });
    // delegate = val?.value || "";

    let coo: any = "";
    const cooVal = leadersData?.find((item: any) => {
      return item.text == addOfferingPortfolioDetails?.op_coo;
    });
    
    coo = cooVal?.value || "";
    const getBody = () => {
      return {
        op_code: addOfferingPortfolioDetails?.op_code,
          op_name: addOfferingPortfolioDetails?.op_name.trim(),
          op_coo: coo,
          // op_coo_delegate:
          //   delegate || addOfferingPortfolioDetails?.op_coo_delegate_id,
          op_leader: lead.value 
      }
      
      // if (delegate === "") {
      //   return {
      //     op_code: addOfferingPortfolioDetails?.op_code,
      //     op_name: addOfferingPortfolioDetails?.op_name,
      //     op_coo: lead.value || addOfferingPortfolioDetails?.op_coo_id,
      //   };
      // } else {
      //   return {
      //     op_code: addOfferingPortfolioDetails?.op_code,
      //     op_name: addOfferingPortfolioDetails?.op_name,
      //     op_coo: lead.value || addOfferingPortfolioDetails?.op_coo_id,
      //     op_coo_delegate:
      //       delegate || addOfferingPortfolioDetails?.op_coo_delegate_id,
      //     op_leader: coo
      //   };
      // }
    };

    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(getBody()),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${addOfferingPortfolioEnums.OPNameExists}`);
        }
        else {
          SuccessMessage(
            `Offering Portfolio ${addOfferingPortfolioDetails?.op_name} added successfully.`
          );
          getOfferingPortfolioData(1);
        }
      } else {
        if(data.responseData.message.includes("already exists")){
          ErrorMessage(`${addOfferingPortfolioEnums.OPCodeExists}`);
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
    setLoading(false);
  };

  const handleOk = () => {
    updateOfferingPortfolioDetails();
    setValueChanged({
      op_code: false,
      op_name: false,
      op_coo: false,
      op_coo_delegate: false,
      op_leader_user: false
    });
    setError("");
    setAddOPModalOpen(false);
  };
  const handleCancel = () => {
    setError("");
    setValueChanged({
      op_code: false,
      op_name: false,
      op_coo: false,
      op_coo_delegate: false,
      op_leader_user: false
    });
    setAddOPModalOpen(false);
  };

  const handleCodeChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setValueChanged({
        ...valueChanged,
        op_code: true,
      });
      setAddOfferingPortfolioDetails({
        ...addOfferingPortfolioDetails,
        op_code: e.target.value,
      });
    }
  };

  const setOfferingPortfolioLead = (val: any) => {
    setValueChanged({
      ...valueChanged,
      op_leader_user: true,
    });
    setAddOfferingPortfolioDetails({
      ...addOfferingPortfolioDetails,
      op_leader_user: val,
    });

  };

  useEffect(() => {
    if(addOfferingPortfolioDetails?.op_leader_user && addOfferingPortfolioDetails?.op_coo){
      const lead = leadersData.find((item: any) => {
        return item.text == addOfferingPortfolioDetails.op_leader_user;
      });
      let coo: any = "";
      const cooVal = leadersData?.find((item: any) => {
        return item.text == addOfferingPortfolioDetails?.op_coo;
      });
      if(lead != undefined && lead!= null  && lead.value == cooVal.value){
         setValidateLeadandCoo(true);
       }
       else{
         setValidateLeadandCoo(false);
       }
    }
  }, [addOfferingPortfolioDetails]);

  const setOfferingPortfolioDelegateLead = (val: any) => {
    setValueChanged({
      ...valueChanged,
      op_coo_delegate: true,
    });
    setAddOfferingPortfolioDetails({
      ...addOfferingPortfolioDetails,
      op_coo_delegate: val,
    });
  };

  const setOfferingPortfolioCoo = (val: any) => {
    setValueChanged({
      ...valueChanged,
      op_coo: true,
    });
    setAddOfferingPortfolioDetails({
      ...addOfferingPortfolioDetails,
      op_coo: val,
    });
  };

  const handleNameChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setValueChanged({
        ...valueChanged,
        op_name: true,
      });
      setAddOfferingPortfolioDetails({
        ...addOfferingPortfolioDetails,
        op_name: e.target.value,
      });
    }
  };

  const setOfferingLeadVal = (val: string) =>{     }
  const setOfferingDelegateVal = (val: string) =>{ }
  const setOfferingCooVal = (val: string) =>{ }

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [addOfferingPortfolioModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
		<>
			<Modal
				open={addOfferingPortfolioModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Title level={5}>{addOfferingPortfolioEnums.heading}</Title>
				<Text>{addOfferingPortfolioEnums.subHeading}</Text>
				<Form
					form={form}
					layout="vertical"
					className="add-user-modal-form"
					onFinish={handleOk}
				>
					<div className="add-user-form-row">
						<Form.Item
							label={addOfferingPortfolioEnums.OPcodeLabel}
							className="add-user-form-item-1"
						>
							<TextField
								onChange={handleCodeChange}
								value={addOfferingPortfolioDetails?.op_code}
								name="op_code"
								// placeholder={addOfferingPortfolioEnums.OPcodeLabel}
								className="textfield"
                maxLength={8}
							/>
						</Form.Item>
						<Form.Item
							label={addOfferingPortfolioEnums.OPNameLabel}
							className="add-user-form-item-2"
							name="name"
							// rules={[
							// 	{
							// 		whitespace: true,
							// 		message: "OP Name cannot be empty.",
							// 	},
							// 	{
							// 		validator: (_, value) =>
							// 			value.trim().length >= 2
							// 				? Promise.resolve()
							// 				: Promise.reject(
							// 						new Error(inputValidationError.min2Character)
							// 				  ),
							// 	},
							// 	{
							// 		max: 500,
							// 		message: inputValidationError.max500Characters,
							// 		type: "string",
							// 	},
							// ]}
						>
							<TextField
								onChange={handleNameChange}
								value={addOfferingPortfolioDetails?.op_name}
								name="name"
								// placeholder={addOfferingPortfolioEnums.OPNameLabel}
								className="add-op-textfield"
                maxLength={100}
							/>
						</Form.Item>
					</div>
					<div className="add-user-form-row">
						<span className="add-user-form-item-dropdown-1">
							<Form.Item>
								<Text>Offering Portfolio Lead *</Text>
								<UsersDropdown
									setInitialVal={setOfferingPortfolioLead} 
									initialVal={addOfferingPortfolioDetails.op_leader_user}
                  setSelectedVal={setOfferingLeadVal} 
								/>
                {validateLeadandCoo == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Offering Lead and COO cannot be same."} />
                )}
							</Form.Item>
						</span>
						<span className="add-user-form-item-dropdown-2">
							<Form.Item>
								<Text>Offering Portfolio COO *</Text>
								{/* <LeadsDropdown
									setInitialVal={setOfferingPortfolioDelegateLead}
									initialVal={addOfferingPortfolioDetails.op_coo}
                  setSelectedVal={setOfferingDelegateVal}
								/> */}
                <LeadsDropdown
									setInitialVal={setOfferingPortfolioCoo}
									initialVal={addOfferingPortfolioDetails.op_coo}
                  setSelectedVal={setOfferingCooVal}
								/>
							</Form.Item>
						</span>
					</div>
					<Form.Item className="add-user-form-btn-row">
						<div className="add-user-form-btn-1" data-testid="cancel-btn">
							<CommonButton
								value="Cancel"
								onClick={handleCancel}
								color="#FFFFFF"
								fontColor="rgba(0, 0, 0, 0.85)"
								border="1px solid #D9D9D9"
							/>
						</div>
						<CommonButton
							value="Add Offering Portfolio"
							onClick={handleOk}
							disabled={!validation || validateLeadandCoo}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddOfferingPortfolio;
