import React, { useEffect, useState } from "react";
import { Modal, Typography, Form, Menu, MenuProps, Table } from "antd";
import TextField from "../../common/input/TextField";
import DropDown from "../../common/input/DropDown";
import CommonButton from "../../common/input/CommonButton";
import "./AddUser.css";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import SuccessMessage from "../../common/input/SuccessMessage";
import ErrorMessage from "../../common/input/ErrorMessage";
import {
  iAddUser,
  iRoleDetails,
  iUserDetails,
} from "../../../shared/interfaces/userManagementInterface";
import { addUserEnums } from "../../../shared/enums/userManagementEnum";

const { Title, Text } = Typography;

const AddUser = ({
  addUserModalOpen,
  setAddUserModalOpen,
  setFilterScope,
  setSearchValue,
  refreshTable,
  searchValue,
  token
}: iAddUser) => {
  const initial_details: iUserDetails = {
    first_name: "",
    last_name: "",
    email_id: "",
    role_id: 0,
    preferred_name: "",
  };
  const [userDetails, setUserDetails] = useState<iUserDetails>(initial_details);
  const [userScope, setUserScope] = useState("");
  const [userScopeVal, setUserScopeVal] = useState(0);
  const [roleItemsDetails, setRoleItemDetails] = useState<iRoleDetails[] | []>(
    []
  );
  const [roleMenuItems, setRoleMenuItems] = useState([]);
  const [valueChanged, setValueChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [emailTableData, setEmailTableData] = useState([]);
  const [scopeDisable, setScopeDisable] = useState(false);

  const columns = [
    {
      title: "",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div>
          <input type="radio" id="rbUser" onClick={() => handleSelect(record)} />
        </div>
      ),
    },
    {
      title: "Alias",
      dataIndex: "EmpAlias",
    },
    {
      title: "Name",
      dataIndex: "EmployeeTxtMed",
    },
    {
      title: "Job Title",
      dataIndex: "EmpJobLevelName",
    },
    {
      title: "Location",
      dataIndex: "EmpSubAreaName",
    },
    {
      title: "Function",
      dataIndex: "EmpCCBusinessName",
    }
  ];

  const getallroles = async () => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.roles}`,
        {
          method: "GET",
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setRoleMenuItems(
            data?.responseData.data.map((roleData: any) => {
              return {
                key: roleData.id,
                label: roleData.display_name,
              };
            })
          );
          setRoleItemDetails(data?.responseData.data || []);
        } else {
          setRoleMenuItems([]);
          setRoleItemDetails([]);
        }
      } else {
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setError("Error");
    }
    setLoading(false);
  };

  const addNewUser = async () => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userDetails),
    };
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage("User "+ userDetails?.preferred_name + " added successfully.");
        //setSearchValue("");
        setFilterScope("All");
        refreshTable(1, searchValue);
      } else {
        if(data.responseData.data.error.includes("already exists")){
          ErrorMessage("Email already exists.");
        }
        else if(data.responseData.data.error.includes("not available in BPM consulting")){
          ErrorMessage("User email " + userDetails.email_id + " cannot be added as it is not available in Master Employee Data.");
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      setError("Error");
    }
  };

  const handleOk = () => {
    addNewUser();
    setAddUserModalOpen(false);
  };
  const handleCancel = () => {
    setUserDetails(initial_details);
    setError("");
    setValueChanged(false);
    setUserScope("");
    setAddUserModalOpen(false);
  };
  
  const handleScopeChange: MenuProps["onClick"] = (e) => {
    setUserDetails({
      ...userDetails,
      role_id: Number(e.keyPath[0]),
    });
    setUserScopeVal(Number(e.keyPath[0]));
    let tmp_role_id = roleItemsDetails.filter((item: any) => {
      if (`${item.id}` === e.keyPath[0]) return item;
    });
    setUserScope(tmp_role_id[0]?.display_name || "");
    setValueChanged(true);
  };
  const [form] = Form.useForm();
  const scopeDropdownMenu = (
    <Menu onClick={handleScopeChange} items={roleMenuItems} />
  );
  
  useEffect(() => {
    if(scopeDisable == false){
      getallroles();
      setScopeDisable(true);
    }
  }, []);
  useEffect(() => {
    if (
			userDetails.preferred_name.trim().length > 0 &&
			userDetails.role_id
		) {
			setValueChanged(true);
		} else {
			setValueChanged(false);
		}
  }, [userDetails]);
  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [addUserModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setAddUserModalOpen(false);
    }
  };

  const handleSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  }

  const getUserByAlias = async () => {
    setLoading(true);
    try {
      setEmailTableData([]);
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.getEmployeeList}`,
        {
          method: "GET",
          queryParam: `alias=${searchTerm.trim()}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          }
        }
      );
      setLoading(false);
      setValueChanged(false);
      setUserDetails(initial_details);
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setEmailTableData(data?.responseData?.data);
        } else {
          setEmailTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      setError("Error");
    }
    
  };

  const handleSearch = () => {
    if(searchTerm != ""){
      getUserByAlias();
    }
  }

  const handleSelect = (val: any) => {
    setUserDetails({
      ...userDetails,
      email_id: val.EmpAlias+"@deloitte.com",
      preferred_name: val.EmployeeTxtMed,
      role_id: userScopeVal
    });
  }

  return (
		<>
			<Modal
				open={addUserModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Title level={5}>{addUserEnums.heading}</Title>
				<Text>{addUserEnums.subHeading}</Text>
				<Form
					form={form}
					layout="vertical"
					className="add-user-modal-form"
					onFinish={handleSearch}
				>
					<div className="add-user-form-row">
						<Form.Item
							label={addUserEnums.searchTermLabel}
							className="add-user-form-item-1"
							name="searchTerm"
						>
              <TextField
                onChange={handleSearchTerm}
								value={searchTerm}
								name="searchTerm"
								placeholder={addUserEnums.SearchTermPlaceholder}
								className="add-user-textfield textfield-margin"
								maxLength={50}
							/>
              <CommonButton
							value="Search"
              id="btnSearchByAlias"
							onClick={handleSearch}
							disabled={searchTerm != "" ? false : true}
						/>
						</Form.Item>
						<Form.Item className="add-user-form-item-2">
							<DropDown
								value={userScope || addUserEnums.scopePlaceholder}
								menu={scopeDropdownMenu}
								className="add-user-dropdown"
								placeholder={addUserEnums.scopeLabel}
								loading={loading}
							/>
						</Form.Item>
					</div>

          <div className="add-user-form-table">
          <Table id="tblEmail"
                columns={columns}
                dataSource={emailTableData}
                pagination={false}
                loading={loading}
              />
          </div>

					<Form.Item className="add-user-form-row-2">
						<div className="add-user-form-btn-1" data-testid="cancel-btn">
							<CommonButton
								value="Cancel"
								onClick={handleCancel}
								color="#FFFFFF"
								fontColor="rgba(0, 0, 0, 0.85)"
								border="1px solid #D9D9D9"
							/>
						</div>
						<CommonButton
							value="Add User"
							onClick={handleOk}
							disabled={!valueChanged}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default AddUser;
