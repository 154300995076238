import React, { useEffect, useState } from "react";
import { Select } from "antd";
import type { SelectProps } from "antd";
import { Endpoints } from "../../../shared/enums/endpoints";

let timeout: ReturnType<typeof setTimeout> | null;
let currentValue: string;

const fetchData = (value: string, callback: Function) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;

   const options = {
    headers: {}
   }
  const callApi = () => {
    fetch(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.user}?role_id=3&contains=${value}`
    , options)
      .then((response: any) => response.json())
      .then((d: any) => {
        if (currentValue === value) {
          const { data } = d;
          const datas = data.map((item: any) => ({
            value: item.id,
            text: item.preferred,
          }));
          callback(datas);
        }
      });
  };

  timeout = setTimeout(callApi, 300);
};

const SearchInput: React.FC<{
  placeholder: string;
  style: React.CSSProperties;

  initialVal: string;
  setInitialVal: any;
  selectedVal : string;
  setSelectedVal: any;
}> = (props) => {
  const [data, setData] = useState<SelectProps["options"]>([]);
  const [value, setValue] = useState<string>();

  const handleSearch = (newValue: string) => {
    if (newValue) {
      fetchData(newValue, setData);
    } else {
      setData([]);
    }
  };

  const handleChange = (newValue: string, label: any) => {
    props.setSelectedVal(newValue);
    props.setInitialVal(label.label);
  };

  return (
    <Select
      showSearch
      virtual={false}
      value={props.initialVal}
      placeholder={props.placeholder}
      style={props.style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      options={(data || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
  );
};

const App = ({ initialVal, setInitialVal, style, selectedVal, setSelectedVal }: any) => (
  <SearchInput
    initialVal={initialVal}
    placeholder=""
    style={style}
    setInitialVal={setInitialVal}
    selectedVal={selectedVal}
    setSelectedVal={setSelectedVal}
  />
);

export default App;
