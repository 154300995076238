import React, { useState, useEffect } from "react";
import { marketOfferingResponse } from "../../../shared/interfaces/HomePageInterfaces";
import { MarketOfferingTable } from "./MarketOfferingTable";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import SuccessMessage from "../../common/input/SuccessMessage";
import ErrorMessage from "../../common/input/ErrorMessage";
import DeleteMo from "./DeleteMo";
import EditMarketOffering from "./EditMarketOffering";
import AddMarketOffering from "./AddMarketOffering";

const MarketOfferingManagement = () => {
  const [marketOfferingData, setMarketOfferingData] = useState<
    marketOfferingResponse[]
  >([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [addMOModalOpen, setAddMOModalOpen] = useState(false);
  const [editMOModalOpen, setEditMOModalOpen] = useState(false);
  const [deleteMOModalOpen, setDeleteMOModalOpen] = useState(false);
  const [searchVal, setSearchVal] = useState<string>("");
  const [moToBeDeleted, setMoToBeDeleted] = useState<{
    id: number;
    mo_name: string;
  } | null>(null);
  const [toBeEditedMOData, setToBeEditedMOData] = useState<any>([]);
  const [analyticLoaded, setAnalyticLoaded] = useState(false);

  useEffect(() =>{
    if(analyticLoaded == false){
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"Market Offering",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined'){
        //window._satellite.track("pageView");
      }
      setAnalyticLoaded(true);
      sessionStorage.setItem("userManagementCall", "false");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "false");
    }
  });

  const fetchMarketOfferingApiResponse = async (
    pageNumber: number = 1,
    params: string = ""
  ) => {
    setLoading(true);
    let searchQP = "";
    setMarketOfferingData([]);
      searchQP = `contains=${encodeURIComponent(
        searchVal.trim()
      )}`;
    const getMarketOfferingData: any = await fetchAPI(
      `${process.env.REACT_APP_BASE_URL}${Endpoints.marketOffering}`,
      {
        method: "GET",
        queryParam: `page=${pageNumber}${searchQP ? "&" + searchQP : ""}`,
        headers: {}
      }
    );
    
    if (!getMarketOfferingData?.errMsg) {
      setMarketOfferingData(getMarketOfferingData?.responseData.data);
      if (pageNumber == 1) {
        setTotalPage(
          getMarketOfferingData?.responseData?.message.total_records
        );
      }
      setLoading(false);
    } else {
      setLoading(false);
      setMarketOfferingData([]);
      console.log("Res Status", getMarketOfferingData?.responseStatus);
    }
    
  };

  const deleteMarketOfferingApiResponse = async () => {
    try {
      const options = {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        queryParam: "mo_code=" + moToBeDeleted?.id + "" || "",
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.marketOffering}`,
        options
      );
      if (!data?.errMsg) {
        SuccessMessage(
          `Market Offering ${moToBeDeleted?.mo_name} deleted successfully.`
        );
        fetchMarketOfferingApiResponse(currentPage);
      } else {
        ErrorMessage("Something went wrong. Please try again later");
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
    setMoToBeDeleted(null);
  };

  const handleDelete = (record: marketOfferingResponse) => {
    setDeleteMOModalOpen(true);
    setMoToBeDeleted({ id: record.mo_code, mo_name: record.mo_name });
  };

  const handleEdit = (record: marketOfferingResponse) => {
    setEditMOModalOpen(true);
    setToBeEditedMOData(record);
  };
  const columns = [
    {
      title: "MO Code",
      dataIndex: "mo_code",
    },
    {
      title: "Market Offering",
      dataIndex: "mo_name",
    },
    {
      title: "Offering",
      dataIndex: ["offering", "offering_name"],
    },
    {
      title: "Market Offering Lead",
      dataIndex: "lead_user",
      render: function (
        text: any,
        record: marketOfferingResponse,
        index: number
      ) {
        if (record && record.mo_lead_user)
          return (
            <>
              {`${record.mo_lead_user.preferred ? record.mo_lead_user.preferred : ""}`}
            </>
          );
        else return <></>;
      },
    },
    {
      title: "Market Offering Delegate",
      dataIndex: "delegate_lead_user",
      render: function (
        text: any,
        record: marketOfferingResponse,
        index: number
      ) {
        if (record && record.mo_delegate_user)
          return (
            <>
              {`${record.mo_delegate_user.preferred ? record.mo_delegate_user.preferred : ""}`}
            </>
          );
        else return <></>;
      },
    },

    {
      title: "Engagement Type",
      dataIndex: ["engagement_type", "engagement_type"],
    },
    {
      title: "Sales Excellence Lead",
      dataIndex: "sales_excellence_user",
      render: function (
        text: any,
        record: marketOfferingResponse,
        index: number
      ) {
        if (record && record.service_excellence_lead_user)
          return (
            <>
              {`${record.service_excellence_lead_user.preferred ? record.service_excellence_lead_user.preferred : ""}`}
            </>
          );
        else return <></>;
      },
    },
    {
      title: "Delivery Excellence Lead",
      dataIndex: "selivery_excellence_lead",
      render: function (
        text: any,
        record: marketOfferingResponse,
        index: number
      ) {
        if (record && record.delivery_excellence_lead_user)
          return (
            <>
              {`${record.delivery_excellence_lead_user.preferred ? record.delivery_excellence_lead_user.preferred : ""}`}
            </>
          );
        else return <></>;
      },
    },
    {
      title: "Bulge/SOC",
      dataIndex: "bulge_soc"
    },
    {
      title: "Bulge/SOC Overall",
      dataIndex: "bulge_soc_overall",
      render: function (
        record: any,
        index: number
      ) {
        if (record)
          return (
            <>
              {`${record.toFixed(2)}`}
            </>
          );
        else return <></>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div className="mo-admin-action-area">
          <a onClick={() => handleEdit(record)}>
            <EditOutlined style={{ fontSize: "18px", color: "#007CB0" }} />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined style={{ fontSize: "18px", color: "#DA291C" }} />
          </a>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchMarketOfferingApiResponse();
  }, []);
  return (
    <>
      {addMOModalOpen && (<AddMarketOffering
        addMarketOfferingModalOpen={addMOModalOpen}
        setAddMarketOfferingModalOpen={setAddMOModalOpen}
        marketOfferingData={toBeEditedMOData}
        getMarketOfferingData={() => fetchMarketOfferingApiResponse()}
        setCurrentPage={setCurrentPage}
      />)}
      {editMOModalOpen && (<EditMarketOffering
        editMarketOfferingModalOpen={editMOModalOpen}
        setEditMarketOfferingModalOpen={setEditMOModalOpen}
        marketOfferingData={toBeEditedMOData}
        getMarketOfferingData={() => fetchMarketOfferingApiResponse()}
        setCurrentPage={setCurrentPage}
      />)}
      <DeleteMo
        moToBeDeleted={moToBeDeleted}
        deleteMarketOfferingApiResponse={deleteMarketOfferingApiResponse}
        deleteModalOpen={deleteMOModalOpen}
        setDeleteModalOpen={setDeleteMOModalOpen}
      />
      <div>
        <MarketOfferingTable
          columns={columns}
          marketOfferingData={marketOfferingData}
          fetchMarketOfferingApiResponse={fetchMarketOfferingApiResponse}
          totalPage={totalPage}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setAddMOModalOpen={setAddMOModalOpen}
          setSearchVal = {setSearchVal}
        />
      </div>
    </>
  );
};

export default MarketOfferingManagement;
