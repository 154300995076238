import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TextField from "../../common/input/TextField";
import HighlightTable from "../../common/highlighter/HighlightTable";
import React, { useEffect, useState } from "react";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import ErrorPage from "../../common/errorPage/ErrorPage";
import CommonButton from "../../common/input/CommonButton";
import "./OfferingPortfolioManagement.css";
import EditOfferingPortfolio from "./EditOfferingPortfolio";
import DropDown from "../../common/input/DropDown";
import DeleteOfferingPortfolio from "./DeleteOfferingPortfolio";
import AddOfferingPortfolio from "./AddOfferingPortfolio";

const OfferingPortfolioManagement = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [offeringPortfolioTableData, setOfferingPortfolioTableData] = useState(
    []
  );
  const [analyticLoaded, setAnalyticLoaded] = useState(false);
  const [addOPModalOpen, setAddOPModalOpen] = useState(false);
  const [editOPModalOpen, setEditOPModalOpen] = useState(false);
  const [toEditData, setToEditData] = useState(null);
  const [deleteOfferingPortfolio, setDeleteOfferingPortfolio] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchOfferingPortfolioParam, setSearchOfferingPortfolioParam] =
    useState("All");
  const [totalPage, setTotalPage] = useState<number>(0);
  const [searchValue, setSearchValue] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);
  const [offeringPortfolioMenuItems, setOfferingPortfolioMenuItems] = useState(
    []
  );
  const [offeringPortfolioItemsDetails, setOfferingPortfolioItemDetails] =
    useState<any>([]);
  const [filterScope, setFilterScope] = useState<string>("All");

  useEffect(() => {
    getOfferingPortfolioData();
    if(analyticLoaded == false) {
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"Offering Portfolio",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined') {
        //window._satellite.track("pageView");
      }
      setAnalyticLoaded(true);
      sessionStorage.setItem("userManagementCall", "false");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "false");
    }
  }, []);

  useEffect(() => {
    if (!searchOfferingPortfolioParam && !searchValue) setEnableBtn(false);
    else if (!searchOfferingPortfolioParam && searchValue) setEnableBtn(false);
    else if (searchOfferingPortfolioParam === "All") {
      setEnableBtn(true);
    } else {
      if (searchValue.trim().length) setEnableBtn(true);
      else setEnableBtn(false);
    }
  }, [searchOfferingPortfolioParam, searchValue]);

  const handleEdit = (val: any) => {
    //here use val.id for getting data
    setEditOPModalOpen(true);
    setToEditData(val);
  };
  const handleDelete = (val: any) => {
    //here use val.id for getting data
    setDeleteOfferingPortfolio(val);
    setDeleteModalOpen(true);
  };

  useEffect(() =>{
    if(searchValue === "" && loading ===false)
    {
      handleSearchButton();
    }
  }, [searchValue])

  const handleDropdownChange: MenuProps["onClick"] = (e) => {
    setSearchOfferingPortfolioParam(e.keyPath[0]);
    if (e.keyPath[0] == "All") {
      setCurrentPage(1);
      getOfferingPortfolioData(1);
    }
    else{
      setSearchValue("");
    }
  };

  const OPsearchFilterMenuItems = (
    <Menu
      onClick={handleDropdownChange}
      items={[
        {
          label: "All",
          key: "All",
        },
        {
          label: "Offering Portfolio",
          key: "Offering Portfolio",
        },
        {
          label: "OP Code",
          key: "OP Code",
        },
      ]}
    />
  );

  const columns = [
    {
      title: "OP Code",
      dataIndex: "op_code",
    },
    {
      title: "Offering Portfolio",
      dataIndex: "op_name",
    },
    // {
    //   title: "Offering Portfolio COO",
    //   dataIndex: ["op_coo_delegate_user"],
    //   render: (_: any, record: any) =>
    //     `${record.op_coo_delegate_user?.first_name || ""} ${
    //       record.op_coo_delegate_user?.last_name || ""
    //     }`,
    // },
    {
      title: "Offering Portfolio Lead",
      dataIndex: ["op_leader_user"],
      render: (_: any, record: any) =>
        `${record.op_leader_user?.preferred ? record.op_leader_user?.preferred : ""}`,
    },
    {
      title: "Offering Portfolio COO",
      dataIndex: ["op_coo_user"],
      render: (_: any, record: any) =>
        `${record.op_coo_user?.preferred ? record.op_coo_user?.preferred : ""}`,
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div className="admin-action-area">
          <a onClick={() => handleEdit(record)}>
            <EditOutlined style={{ fontSize: "18px", color: "#007CB0" }} />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined style={{ fontSize: "18px", color: "#DA291C" }} />
          </a>
        </div>
      ),
    },
  ];
  const getFilteredDetail = async (currentPage: number = 1, params: string) => {
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}${params ? "&" + params : ""}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setOfferingPortfolioTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setOfferingPortfolioTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
      
    } catch (err) {
      setLoading(false);
      console.log("Res Status", err);
      setError("error");
    }
    
  };

  const getOfferingPortfolioData = async (currentPage: number = 1) => {
    setLoading(true);
    let searchQP = "";
    setOfferingPortfolioTableData([]);
    try {
      searchQP = `${encodeURIComponent(
        searchValue?.trim()
      )}`;
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}&contains=${searchQP}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setOfferingPortfolioTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setOfferingPortfolioTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
      
    } catch (err) {
      setLoading(false);
      setError("Error");
    }
    
  };
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getOfferingPortfolioData(pageNumber);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleSearchButton = () => {
    setCurrentPage(1);
    getOfferingPortfolioData();
  };

  const handleSearch = () => {
    if(loading == false){
      if (searchOfferingPortfolioParam === "All") {
        setCurrentPage(1);
        getOfferingPortfolioData();
        return;
      }
      if (!searchOfferingPortfolioParam || !searchValue || !enableBtn) return;
      setCurrentPage(1);
      let searchQP = "";
      if (searchOfferingPortfolioParam === "Offering Portfolio"){
        searchQP = `op_name=${encodeURIComponent(
          searchValue.trim()
        )}`;
      }
      else if (searchOfferingPortfolioParam === "OP Code")
        searchQP = `op_code=${encodeURIComponent(
          searchValue.trim()
        )}`;
  
      getFilteredDetail(1, searchQP);
    }
  };

  // const filterScopeDropdownMenu = (
  //   <Menu
  //     onClick={handleScopeChange}
  //     items={[
  //       {
  //         label: "All",
  //         key: "All",
  //       },
  //       ...offeringPortfolioMenuItems,
  //     ]}
  //   />
  // );

  return (
    <Form onFinish={handleSearch}>
      {error === "" ? (
        <>
          {addOPModalOpen && (
            <AddOfferingPortfolio
              addOfferingPortfolioModalOpen={addOPModalOpen}
              setAddOPModalOpen={setAddOPModalOpen}
              getOfferingPortfolioData={getOfferingPortfolioData}
            />
          )}
          {editOPModalOpen &&(<EditOfferingPortfolio
            editOPModalOpen={editOPModalOpen}
            setEditOPModalOpen={setEditOPModalOpen}
            offeringPortfolioData={toEditData}
            getOfferingPortfolioData={getOfferingPortfolioData}
            currentPage={currentPage}
          />)}
          <DeleteOfferingPortfolio
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            offeringPortfolio={deleteOfferingPortfolio}
            setOfferingPortfolio={setDeleteOfferingPortfolio}
            getOfferingPortfolioData={getOfferingPortfolioData}
            currentPage={currentPage}
          />
          <>
            <div className="user-main">
              <div className="label">
                {/* <div className="mo-admin-header-filter">
                  <DropDown
                    value={searchOfferingPortfolioParam || "Search Using"}
                    menu={OPsearchFilterMenuItems}
                    placeholder=""
                  />
                </div> */}
                <div className="search">
                  <TextField
                    onChange={handleSearchChange}
                    value={searchValue}
                    disabled={!searchOfferingPortfolioParam}
                    name="search"
                    style={{ width: '30em' }}
                    placeholder="Search by OP Code, Offering Portfolio, Offering Portfolio Lead, Offering Portfolio COO."
                    className="op-admin-header-search-textfield textfield"
                  />
                  <CommonButton id="btnOfferingPortfolioSearch"
                    onClick={handleSearch}
                    value={"Search"}
                  ></CommonButton>
                </div>
              </div>
              <CommonButton
                onClick={() => setAddOPModalOpen(true)}
                value="Add Offering Portfolio"
              ></CommonButton>
            </div>
            <>
            <HighlightTable columns={columns} pagination={false}
                loading={loading} tableData={offeringPortfolioTableData} searchValue={searchValue} startPosition={5}/>
              {/* <Table id="tblData"
                columns={columns}
                dataSource={offeringPortfolioTableData}
                pagination={false}
                loading={loading}
              /> */}

              {offeringPortfolioTableData.length > 0 ? 
              <Pagination
                className="user-paginantion"
                onChange={handlePagination}
                defaultCurrent={currentPage}
                current={currentPage}
                total={totalPage}
              /> : ""}
              
            </>
          </>
        </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </Form>
  );
};

export default OfferingPortfolioManagement;
