import { Button, Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import CommonButton from "../../common/input/CommonButton";
import Label from "../../common/input/Label";
import "./EditOffering.css";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import { ListFormat } from "typescript";
import LeadsDropdown from "./LeadsDropdown";
import OpDropdown from "./OpDropdown";
import { addOfferingEnums, inputValidationError, validateResponse } from "../../../shared/enums/userManagementEnum";
import UnEditableDropDown from "../../common/input/UnEditableDropdown";

const { Title, Text } = Typography;


const EditOffering = ({
  editOfferingModalOpen,
  setEditOfferingModalOpen,
  offeringData,
  getOfferingData,
  setCurrentPage,
}: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [valueChanged, setValueChanged] = useState(false);
  const [leadersData, setLeadersData] = useState<any>([]);
  const [oPData, setOpData] = useState<any>([]);
  const [validateLeadandDelegate, setValidateLeadandDelegate] = useState(false);
  const [offering_lead_id, setOffering_lead_id] = useState<string>("");
  const [offering_delegate_id, setOffering_delegate_id] = useState<string>("");
  const [offeringEditDetails, setOfferingEditDetails] = useState({
    offering_code: "",
    op_code: "",
    op_name: "",
    offering_name: "",
    offering_lead: "",
    offering_delegate: "",
    offering_lead_id: "",
    offering_delegate_id: "",
  });

  useEffect(() => {
    if (
			offeringEditDetails.op_name != "" &&
			offeringEditDetails?.offering_name.trim() != "" &&
			offeringEditDetails?.offering_name.trim().length >= 2 &&
			offeringEditDetails.offering_lead != "" &&
      offering_lead_id != offering_delegate_id
		) {
			setValidated(true);
		} else {
			setValidated(false);
		}
  }, [offeringEditDetails]);

  useEffect(() => {
    setValidateLeadandDelegate(false);
    setOfferingEditDetails({
      offering_code: offeringData?.offering_code || "",
      op_code: offeringData?.op_code || "",
      op_name: offeringData?.offering_portfolio?.op_name || "",
      offering_name: offeringData?.offering_name || "",
      offering_lead:
        (offeringData?.offering_lead_user?.preferred || "") ,
      offering_lead_id: offeringData?.offering_lead_user?.id || "",
      offering_delegate:
        (offeringData?.offering_delegate_user?.preferred || "") ,
      offering_delegate_id: offeringData?.offering_delegate_user?.id || "",
    });
    callApi();
    callApiOP();
    if(offeringData != null && offeringData.offering_lead_user != null && offeringData.offering_delegate_user != null){
      if(offeringData.offering_lead_user.id == offeringData.offering_delegate_user.id){
        setValidateLeadandDelegate(true);
      }
    }
    setOffering_lead_id(offeringData!= null ? offeringData.offering_lead_user != null ? offeringData.offering_lead_user.id : "":"");
    setOffering_delegate_id(offeringData!= null ? offeringData.offering_delegate_user != null ? offeringData.offering_delegate_user.id : "":"");
  }, [editOfferingModalOpen]);
  const options = {
    headers: {}
  }
  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}?role_id=3`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.preferred,
        }));
        setLeadersData(datas);
      });
  };
  const callApiOP = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.op_code,
          text: item.label,
        }));
        setOpData(datas);
      });
  };

  const handleOk = () => {
    updateOfferingDetails();
    setCurrentPage(1);

    setValueChanged(false);
    setError("");
    setEditOfferingModalOpen(false);
    setLeadersData("");
  };
  const handleCancel = () => {
    setError("");
    setLeadersData("");
    setValueChanged(false);
    setEditOfferingModalOpen(false);
  };
  const handleScopeChange: MenuProps["onClick"] = (e) => {
    //setScope(e.keyPath[0]);
    setValueChanged(true);
  };
  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setOfferingEditDetails({
        ...offeringEditDetails,
        [e.target.name]: e.target.value,
      });
      setValueChanged(true);
    }
  };

  const setOfferingLead = (val: any) => {
    setValueChanged(true);
    setOfferingEditDetails({
      ...offeringEditDetails,
      offering_lead: val,
    });
  };
  const setOfferingDelegate = (val: any) => {
    setValueChanged(true);
    setOfferingEditDetails({
      ...offeringEditDetails,
      offering_delegate: val,
    });
  };

  const setOfferingLeadVal = (val: string) =>{
    setOffering_lead_id(val);
    setValidateLeadandDelegate(val == offering_delegate_id ? true: false);
  }

  const setOfferingDelegateVal = (val: string) =>{
    setOffering_delegate_id(val);
    setValidateLeadandDelegate(val == offering_lead_id ? true : false);
  }

  const setOfferingPortfolio = (val: any) => {
    setValueChanged(true);
    setOfferingEditDetails({
      ...offeringEditDetails,
      op_name: val,
    });
  };
  const getBody = (lead: any, delegate: any, op: any) => {
    if (delegate == "") {
      return {
        offering_name: offeringEditDetails?.offering_name.trim(),
        op_code: op,
        offering_lead: lead,
      };
    } else
      return {
        offering_name: offeringEditDetails?.offering_name.trim(),
        op_code: op,
        offering_lead: lead,
        offering_delegate: delegate,
      };
  };

  const updateOfferingDetails = async () => {
    const lead = leadersData.find((item: any) => {
      return item.text == offeringEditDetails.offering_lead;
    });
    let delegate: any = "";
    const val = leadersData?.find((item: any) => {
      return item.text == offeringEditDetails?.offering_delegate;
    });
    delegate = val?.value || "";
    const op = oPData.find((item: any) => {
      return item.text == offeringEditDetails.op_name;
    });

    try {
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        queryParam: "offering_code=" + offeringEditDetails?.offering_code || "",
        body: JSON.stringify(getBody(lead.value, delegate, op.value)),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${addOfferingEnums.OfferingNameExists}`);
        }
        else{
          SuccessMessage(`Offering ${offeringEditDetails?.offering_name} updated successfully.`);
          getOfferingData();
        }
      } else {
        ErrorMessage("Something went wrong. Please try again later");
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [editOfferingModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
		<>
			<Modal
				open={editOfferingModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Form
					onFinish={handleOk}
					form={form}
					layout="vertical"
					className="edit-offering-form"
					fields={[
						{
							name: ["offering_name"],
							value: offeringEditDetails.offering_name,
						},
					]}
				>
					<Title level={5}>Edit Offering</Title>
					<Text>This will help you to edit an offering in the framework.</Text>
					<div className="admin-offering-input-main">
						<div className="admin-offering-row">
							<div className="offering-input">
              <UnEditableDropDown
									placeholder={addOfferingEnums.OfferingCodeLabel}
									value={offeringEditDetails.offering_code || "--"}
								/>
							</div>
							<div className="offering-input">
								<Text>Offering Portfolio *</Text>

								<OpDropdown
									initialVal={offeringEditDetails.op_name}
									setInitialVal={setOfferingPortfolio}
								/>
							</div>
						</div>
						<div className="admin-offering-row">
							<div className="offering-input">
								<Form.Item
									name="offering_name"
									label="Offering Name *"
									// rules={[
									// 	{
									// 		whitespace: true,
									// 		message: "Offering Name cannot be empty.",
									// 	},
									// 	{
									// 		validator: (_, value) =>
									// 			value.trim().length >= 2
									// 				? Promise.resolve()
									// 				: Promise.reject(new Error(inputValidationError.min2Character)),
									// 	},
									// 	{
									// 		max: 500,
									// 		message: inputValidationError.max500Characters,
									// 		type: "string",
									// 	},
									// ]}
									// key={offeringEditDetails.offering_code}
								>
									<TextField
										onChange={handleInputChange}
										value={offeringEditDetails.offering_name}
										placeholder="Enter Offering Name"
										className="offering-textfield"
										name="offering_name"
                    maxLength={100}
									/>
								</Form.Item>
							</div>
							<div className="offering-input">
								<Text>Offering Lead *</Text>
								<LeadsDropdown
									initialVal={offeringEditDetails.offering_lead}
									setInitialVal={setOfferingLead}
                  setSelectedVal={setOfferingLeadVal}
								/>
							</div>
						</div>
						<div className="admin-offering-row">
							<div className="offering-input">
								<Text>Offering Delegate</Text>
								<LeadsDropdown
									initialVal={offeringEditDetails.offering_delegate}
									setInitialVal={setOfferingDelegate}
                  setSelectedVal={setOfferingDelegateVal}
								/>
                {validateLeadandDelegate == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Offering Lead and Delegate cannot be same."} />
                )}
							</div>
						</div>
					</div>
					<div className="admin-offering-buttons">
						<CommonButton
							value="Cancel"
							onClick={handleCancel}
							color="#FFFFFF"
							fontColor="rgba(0, 0, 0, 0.85)"
							border="1px solid #D9D9D9"
						/>
						<CommonButton
							value="Update"
							onClick={handleOk}
							disabled={!validated || !valueChanged}
							className="save-button"
						/>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default EditOffering;
