import React, { useEffect, useState } from "react";
import "antd/dist/antd.css"; //for antd css and apply style globally
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from "react-router-dom";
import { graphConfig } from "./authConfig";
import ErrorPage from "./components/common/errorPage/ErrorPage";
import Footer from "./components/common/footer/Footer";
import { PageLayout } from "./components/common/loginIntegration/PageLayout";
import Leadership from "./components/leadership";
import Primary from "./components/primary";
import { accessTokenKey, deloitteId } from "./shared/config/Constants";
import { fetchAPI } from "./shared/custom-hooks/fetchAPI";
import Admin from "./components/admin";
import fetchIntercept from "fetch-intercept";
import { Endpoints } from "./shared/enums/endpoints";
import Loader from "./components/common/loader/Loader";
import { useParams } from 'react-router-dom';
import { LocalhostEnum } from "./shared/enums/localhostEnum";

function App() {
  let userName: string = "";
  let accessToken: string = "";
  let userRoleId: Number;
  let userEmail: string = "";
  let userProfilePic = null;

  const [isAdmin, setIsAdmin] = useState(false);
  const [isLeader, setIsLeader] = useState(false);
  const [isNormalUser, setIsNormalUser] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isLeaderLoggedIn, setAlreadyLoggedInAsLeader] = useState(false);
  const [serviceFailure, setServiceFailure] = useState(false);
//   const navigate = useNavigate();
//   const handleClick = () => navigate("/error");



	if(!window.location.href.includes(LocalhostEnum.url)){
		fetchIntercept.register({
			request: function (url, options) {
				// Modify the url or config here

				const urlList = ["admin", "analytics", "askexpert", "opportunity", "fileuploaderapi"];
				urlList.forEach(urls=> {
					if(!url.toLowerCase().includes("amazonaws.com/opportunity_extract") && url.toLowerCase().includes(urls)) {
						options.headers.Authorization = "Bearer " + accessToken;
					}
				})

				return [url, options];
			},

			requestError: function (error) {
				// Called when an error occured during another 'request' interceptor call
				return Promise.reject(error);
			},

			response: function (response) {
				// Modify the reponse object
				return response;
			},

			responseError: function (error) {
				// Handle an fetch error
				console.log("error : " + error);
				if (
					error?.message == "Failed to fetch" &&
					sessionStorage.getItem("tokenExpired") != "true"
				) {
					sessionStorage.clear();
					sessionStorage.setItem("tokenExpired", "true");
					setIsError(true);
					setServiceFailure(true);
					window.location.reload();
				}
				return Promise.reject(error);
			},
		});
	}

	sessionStorage.setItem("loadFromWelcome", "false");
	sessionStorage.setItem("loadOfferingFromWelcome", "false");
	sessionStorage.setItem("searchJOFromWelcome", "false");
	sessionStorage.setItem("dashboardCall", "false");
	sessionStorage.setItem("adminCall", "false");
	sessionStorage.setItem("fromCP3", "");

  const getUserDetails = async () => {
    const getUserData: any = await fetchAPI(`${graphConfig.graphMeEndpoint}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!getUserData?.errMsg) {
      userRoleId = getUserData?.responseData.jobTitle;
    } else {
      console.log("Res Status", getUserData?.responseStatus);
    }
    // <ErrorPage
    //   responseStatus={getErrorResponseStatus(getOfferingData.responseStatus)}
    // />;
  };

  const getUserPic = async () => {
    const userPictureApi: any = await fetchAPI(`${graphConfig.profilePic}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });
    if (!userPictureApi?.errMsg) {
      userProfilePic = userPictureApi?.responseData;
    } else {
      console.log("Res Status", userPictureApi?.responseStatus);
    }
    // <ErrorPage
    //   responseStatus={getErrorResponseStatus(getOfferingData.responseStatus)}
    // />;
  };

  const cookieBanner = () => {
	var guid = "";
      if (window.location.host.toLowerCase() != "spp.deloitte.com")
        guid = "85c84777-8e26-4454-a22f-9d230d44f71f-test";
      else
        guid = "85c84777-8e26-4454-a22f-9d230d44f71f";
      var otScript = document.createElement('script');
      otScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      otScript.type = "text/javascript";
      otScript.charset = "UTF-8";
      otScript.setAttribute('async', 'false');
      otScript.setAttribute('data-domain-script', guid);
      document.head.appendChild(otScript);
  }

  const getUserAuthorization = async () => {
	const getUserAuthorizationData: any = await fetchAPI(
		`${process.env.REACT_APP_CHART_URL}${Endpoints.login}`,
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: "Bearer " + accessToken,
			},
		}
	);
		
    if (getUserAuthorizationData?.responseStatus == 200) {
			sessionStorage.setItem("tokenExpired", "false");
			sessionStorage.setItem("userType", "");
			userRoleId = getUserAuthorizationData?.responseData.data.user_role_id;
			sessionStorage.setItem("preferred_name_user", getUserAuthorizationData?.responseData.data.preferred_name);
			setLoading(false);
			cookieBanner();
			switch (userRoleId) {
				case 1:
					setIsAdmin(true);
					setIsLeader(true);
					setIsNormalUser(true);
					sessionStorage.setItem("userType", "Admin");
					break;
				case 3:
					setIsAdmin(false);
					setIsLeader(true);
					setIsNormalUser(true);
					setAlreadyLoggedInAsLeader(true);
					sessionStorage.setItem("userType", "TeamLead");
					break;
				default:
					setIsAdmin(false);
					setIsLeader(false);
					setIsNormalUser(true);
					sessionStorage.setItem("userType", "TeamLead");
					break;
			}
		} else {
			if (accessToken != undefined) {
				setIsError(true);
			}
			if (getUserAuthorizationData?.responseStatus !== "401") {
				setServiceFailure(true);
			}
			setLoading(false);
		}
	};

  const getUserInformation = async () => {
		//getUserDetails();
		//getUserPic();
        getUserAuthorization();
	};

  useEffect(() => {
	const keys = Object.keys(sessionStorage);
	if(keys != undefined && keys!=null && keys.length>0 && accessToken!=undefined && accessToken!= null && accessToken!="")
		getUserInformation();
	}, []);

  try {
    const keys = Object.keys(sessionStorage);
    let loginDetailsKey = "";
    for (let key in keys) {
      if (keys[key].includes(deloitteId)) {
        loginDetailsKey = keys[key].substring(0, keys[key].indexOf("."));
      }
    }
    const UserData: any = sessionStorage.getItem(loginDetailsKey + deloitteId);
    const userDesignationKey: any = sessionStorage.getItem(
      //loginDetailsKey + accessTokenKey
	   loginDetailsKey + `${process.env.REACT_APP_Access_Token_Key}`
    );
	if(window.location.href.includes(LocalhostEnum.url)){
		accessToken = LocalhostEnum.accessToken; 
    	userName = LocalhostEnum.username;
		userEmail = LocalhostEnum.userEmail;
	}
	else{
		accessToken = JSON.parse(userDesignationKey)?.secret;
		userName = JSON.parse(UserData)?.name;
		userEmail = JSON.parse(UserData)?.username;
	}
	sessionStorage.setItem('userName',userName);
	sessionStorage.setItem('seachVal', "");
  } catch (err) {
    console.log("User Name not found");
  }

  return (
	<PageLayout>
		<Loader isLoading={isLoading} fullPage={true} plainLoader={true}>
			<Router>
				<Routes>
					{isError && (
						<Route path="/" element={<Navigate to="/error" replace />} />
					)}

					<Route
						path="/admin"
						element={
							isAdmin ? (
								<Admin userName={userName} userEmail={userEmail} />
							) : isError || isLeader || isNormalUser ? (
								<ErrorPage
									responseStatus={serviceFailure ? "500" : "403"}
									isServiceFailure={
										!serviceFailure && isError
											? false
											: serviceFailure
											? serviceFailure
											: isError
									}
								/>
							) : (
								<div></div>
							)
						}
					></Route>
					{/* {isLeader &&
						!isAdmin &&
						sessionStorage.getItem("isLeaderLoggedIn") !== "true" && (
							<Route path="/" element={<Navigate to="/leader" replace />} />
						)} */}
					{((isLeader || isAdmin || isNormalUser)) && (
						<Route
							path="/"
							element={
								isNormalUser ? (
									<Primary
										userName={userName}
										userEmail={userEmail}
										isAdmin={isAdmin}
										isLeader={isLeader}
									/>
								) : isError ? (
									<ErrorPage
										responseStatus={serviceFailure ? "500" : "403"}
										isServiceFailure={
											!serviceFailure && isError
												? false
												: serviceFailure
												? serviceFailure
												: isError
										}
									/>
								) : (
									<div></div>
								)
							}
						></Route>
					)}

					{((isLeader || isAdmin || isNormalUser)) && (
						<Route
							path="/dashboard"
							element={
								isNormalUser ? (
									<Primary
										userName={userName}
										userEmail={userEmail}
										isAdmin={isAdmin}
										isLeader={isLeader}
									/>
								) : isError ? (
									<ErrorPage
										responseStatus={serviceFailure ? "500" : "403"}
										isServiceFailure={
											!serviceFailure && isError
												? false
												: serviceFailure
												? serviceFailure
												: isError
										}
									/>
								) : (
									<div></div>
								)
							}
						></Route>
					)}


					<Route
						path="/error"
						element={
							isError && (
								<ErrorPage
									responseStatus={serviceFailure ? "500" : "403"}
									isServiceFailure={
										!serviceFailure && isError
											? false
											: serviceFailure
											? serviceFailure
											: isError
									}
								/>
							)
						}
					></Route>
					<Route path="" element={<div></div>}></Route>
					<Route
						path="*"
						element={
							// <ErrorPage responseStatus="404" isServiceFailure={false} />
							<ErrorPage
									responseStatus={serviceFailure ? "500" : "403"}
									isServiceFailure={
										!serviceFailure && isError
											? false
											: serviceFailure
											? serviceFailure
											: isError
									}
								/>
						}
					></Route>
				</Routes>
			</Router>
			<Footer></Footer>
		</Loader>
	</PageLayout>
	);
}

export default App;
