import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import "./WelcomeDialog.css";
import CommonButton from "../input/CommonButton";
import TextField from "../input/TextField";
import { SuggestionLabel } from "./SuggestionLabel";
import { welcomeDialog } from "../../../shared/enums/homePageEnum";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { welcomeDialogInterface } from "../../../shared/interfaces/welcomeDialogInterface";
import Loader from "../loader/Loader";
import { localStorageSuggestionsKey } from "../../../shared/config/Constants";
import { addToSuggestions } from "../../../shared/utils/welcomeDialog";
import { useLocation } from "react-router-dom";

const WelcomeDialog = ({
  titleText,
  modelOpen,
  joId,
  setSearchMercuryApiData,
  setSearching,
  setMarketOffering,
  setMarketOfferingDetails,
  setOfferingPortfolioDetails,
  setOfferingDetails,
  setSelectedMOData,
  setOffering,
  setOfferingPortfolio,
  setJoId
}: welcomeDialogInterface) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(modelOpen);
  const [jupiterID, setJupiterID] = useState("");
  const [suggestions, setSuggestions] = useState<any>(
    JSON.parse(localStorage.getItem(localStorageSuggestionsKey) || "")
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
  if(joId){
      if(sessionStorage.getItem("fromCP3") == "true"){
        setJupiterID(joId);
      }
    }
  },[joId]);
  useEffect(() =>{
    if(sessionStorage.getItem("fromCP3") == "true"){
      fetchOpportunityApiResponse();
      sessionStorage.setItem("fromCP3", "false");
    }
  },[jupiterID])
  useEffect(() => {
    setError("");
    setJupiterID("");
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [isModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      setIsModalOpen(false);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setJupiterID("");
    setIsModalOpen(false);
  };

  const {search} = useLocation();
  const urlparams = new URLSearchParams(search);
  const joFromURL = urlparams.get("jo");

  const handleSearch = () => {
    if (jupiterID) {
      sessionStorage.setItem("isJupiterModified","");
      fetchOpportunityApiResponse();
    }
    
  };

  const handleSuggestion = () =>{
    sessionStorage.setItem("isJupiterModified","");
    fetchOpportunityApiResponse();
  }

  const handleChange = (e: string) => {
    if (error) setError("");
    setJupiterID(e);
  };

  const fetchOpportunityApiResponse = async () => {
    setLoading(true);
    window.analyticsDataLayer = {
      pageInfo:{
        pageName:"Welcome",
        userID: sessionStorage.getItem('userName'),
        applicationName: "Strategic Pricing Portal",
        userType: sessionStorage.getItem("userType"),
        searchTerm: jupiterID
      }
    };
    if(typeof window._satellite !== 'undefined'){
      window._satellite.track("search");
    }
    sessionStorage.setItem("jupiterID", jupiterID);
    const getOpportunityApiData = await fetchAPI(
      `${process.env.REACT_APP_SEARCH_URL}${Endpoints.jupiterId}`,
      {
        method: "GET",
        queryParam:
          "mercury_id=" + encodeURIComponent(jupiterID.replaceAll(" ", "")),
        headers: {}
      }
    );

    if (!getOpportunityApiData?.errMsg) {
      if (JSON.stringify(getOpportunityApiData?.responseData.data) !== "[]" && JSON.stringify(getOpportunityApiData?.responseData.data) !== "{}") {
        addToSuggestions(jupiterID.replaceAll(" ", ""), setSuggestions);
        setSearchMercuryApiData(getOpportunityApiData?.responseData.data);
        setError("");
        setSelectedMOData(getOpportunityApiData?.responseData.data[0]);
        setSearching(true);
        setMarketOffering(
          getOpportunityApiData?.responseData.data[0]?.mo?.mo_name
        );
        setOffering(
          getOpportunityApiData?.responseData.data[0]?.offering?.offering_name
        );
        sessionStorage.setItem("searchJOFromWelcome", "true");
        setOfferingPortfolio(
          getOpportunityApiData?.responseData.data[0]?.offering_portfolio
            ?.op_name
        );
        setOfferingPortfolioDetails(getOpportunityApiData?.responseData.data[0]?.offering_portfolio);
        setOfferingDetails(
          getOpportunityApiData?.responseData.data[0]?.offering
        );
        let tempMODetails = [{}];
        tempMODetails[0] = getOpportunityApiData?.responseData.data[0]?.mo;
        setMarketOfferingDetails(tempMODetails);

        setJupiterID("");
        setIsModalOpen(false);
      } else {
        setError(welcomeDialog.noDataFoundonSearchMsg);
      }
    } else {
      setError(welcomeDialog.issueOnBackEndOnSearchMsg);
    }
    setLoading(false);
  };

  return (
    <>
      <CommonButton value={welcomeDialog.searchJupiterID} onClick={showModal} />
      <Modal
        bodyStyle={{ height: "15.5rem", margin: "auto" }}
        centered
        width="60%"
        data-testid="welcome-modal"
        open={isModalOpen}
        footer={null}
        closable={false}
        afterClose={() => {
          document.removeEventListener("keyup", escFunction, false);
        }}
      >
        <Loader isLoading={loading} fullPage={false}>
          <div data-testid="welcome-modal-open">
            <div className="modal-header">
              <p className="modal-title">{titleText} </p>
              <div className="skip-button">
                <CommonButton
                  border="1px solid #D9D9D9"
                  color="#FFFFFF"
                  fontColor="#000000"
                  value={welcomeDialog.skipToDashboard}
                  onClick={handleCancel}
                />
              </div>
            </div>
            <span className="error">{error}</span>
            <Form form={form} onFinish={handleSearch}>
              <div className="input-class">
                <TextField
                  data-testid="modal-text"
                  value={jupiterID}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleChange(e.target.value)
                  }
                  placeholder={welcomeDialog.jupiterIdPlaceholder}
                  type="text"
                  className="modal-text"
                />
                <div className="search-button">
                  <CommonButton
                    data-testid="search-test"
                    disabled={!jupiterID}
                    value={welcomeDialog.search}
                    onClick={handleSearch}
                    width="90%"
                    height="2.5rem"
                    id="btnSearchJupiter"
                  />
                </div>
              </div>
            </Form>
            <div className="suggestion-labels">
              {suggestions.map(
                (suggestionLabelValue: string, index: number) => (
                  <SuggestionLabel
                    index={index}
                    fetchOpportunityApiResponse={() =>
                      handleSuggestion()
                    }
                    setJupiterID={setJupiterID}
                    setError={setError}
                    placeholder={suggestionLabelValue}
                  />
                )
              )}
            </div>
          </div>
        </Loader>
      </Modal>
    </>
  );
};

export default WelcomeDialog;
