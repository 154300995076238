import "./CommonChart.css";
import DropDown from "../input/DropDown";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Scatter } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import { Menu, MenuProps } from "antd";
import { MarginChartProps } from "../../../shared/interfaces/chartInterface";
import { chartLegendColors } from "../../../shared/enums/chartEnums";
import { numberWithCommas, round } from "../../../shared/utils/graphUtil";
import React, { useEffect, useState } from "react";

ChartJS.register(
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  annotationPlugin
);

export function MarginChart({
  xlabel,
  ylabel,
  apiData,
  labelValues,
  timeFrameValue,
  setTimeFrameValue,
  nsrValueOptions,
  nsrRangeValue,
  setNsrRangeValue,
  setHighLightValue,
  highlightValue,
  searching
}: MarginChartProps) {
  const { lowerQuartileValue, upperQuartileValue, centerQuartileLineValue } =
    labelValues;
  //TODO:add type
  let colorindex = -1;

  if (nsrRangeValue !== "All") {
    colorindex = Object.keys(nsrValueOptions).findIndex(
      (p) => p == nsrRangeValue
    );
  }

  let apiDataCopy = apiData.datasets;

  if(apiData?.datasets?.length > 0 && sessionStorage.getItem("isJupiterModified")== "" && searching == true){
    let i;
    for(i=0; i<apiData.datasets.length; i++){
      if(apiData.datasets[i]?.backgroundColor && apiData.datasets[i]?.backgroundColor == "#008000"){
        if(sessionStorage.getItem("isJupiterModified")== "") {
          apiDataCopy[i] = {data:[]} };
        }
    }
    
    apiData.datasets = apiDataCopy;
  }

  const apiDataWithColors = apiData.datasets;

  //Chart options and plugins
  const marginOptions: any = {
    //maintainAspectRatio: false,
    scales: {
      //x-axis formatting
      x: {
        beginAtZero: true,
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + round(value / 1000000, 2) + "M";
          },
        },
        title: {
          display: true,
          align: "center",
          text: xlabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: false,
        },
      },
      //y-axis formatting
      y: {
        min: 20,
        //beginAtZero: true,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return round(value, 2) + "%";
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      legend: {
        display: false,
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: "#007CB0",
        bodyFontColor: "#FFFFFF",
        bodyFont: {
          family: "Open Sans",
          size: 12,
          style: "normal",
          weight: 400,
        },
        cornerRadius: 0,
        bodySpacing: 4,
        padding: 8,
        callbacks: {
          label: (context: any) => {
            let accountRelation = context.raw.client_program ? "Account Relationship : " + (context.raw.client_program == "PLATINUM RELATIONSHIP"? 'Platinum'
              : (context.raw.client_program == "PREMIER RELATIONSHIP"? 'Premier':
              (context.raw.client_program == "ELITE RELATIONSHIP"? 'Elite':(context.raw.client_program == "STRATEGIC RELATIONSHIP"? 'Strategic':'-')))) : "";
              let organization = context.raw.new_addon_extension ? "Origination : " + (context.raw.new_addon_extension=="1"?'New':
              (context.raw.new_addon_extension=="2"?'Add-on':(context.raw.new_addon_extension=="3"?'Extension':'-'))) : "";
              // let organization = context.raw.new_addon_extension ? "Origination : " + (context.raw.new_addon_extension=="NEW"?'New':
              // (context.raw.new_addon_extension=="ADD-ON"?'Add-on':(context.raw.new_addon_extension=="EXTENSION"?'Extension':'-'))) : "";
              let rateCard = context.raw.msa_rate_card_type ? "Rate Card Used : " + (context.raw.msa_rate_card_type=="No"?'None':
              (context.raw.msa_rate_card_type=="Yes - Level Based"?'Level-based':(context.raw.msa_rate_card_type=="Yes - Role Based"?'Role-based':'-'))) : "";
              let secondaryMarketOffering = context.raw.secondary_market_offering_a ? "Secondary Market Offering : " + ((context.raw.secondary_market_offering_a!= undefined 
                && context.raw.secondary_market_offering_a!= null && context.raw.secondary_market_offering_a!='')?context.raw.secondary_market_offering_a:'-') : "";

              let totalBulgeorSpan = context.raw.bulge_soc ? context.raw.bulge_soc == "Span of Control" && context.raw.total_span_of_control ? 
                                    "Span of Control : " + context.raw.total_span_of_control : context.raw.bulge_soc == "Bulge Ratio" && context.raw.total_bulge_ratio ?
                                     "Bulge Ratio : " + context.raw.total_bulge_ratio : "" : "";
              let totalEngagementRevenuePerHour = context.raw.total_engagement_revenue_per_hour ? "RPH : " + context.raw.total_engagement_revenue_per_hour : "";
              
              let client = context.raw.client_program ? context.raw.client_program : "";

              let coordinateStrArr = [];
              if(accountRelation && accountRelation!='' && accountRelation!='Account Relationship : -') {coordinateStrArr.push(accountRelation);}
              if(organization) {coordinateStrArr.push(organization);}
              if(rateCard && rateCard!='' && rateCard!='Rate Card Used : -') {coordinateStrArr.push(rateCard);}
              if(secondaryMarketOffering) {coordinateStrArr.push(secondaryMarketOffering);}
              if(totalBulgeorSpan) {coordinateStrArr.push(totalBulgeorSpan);}
              if(client == "Potential Engagement") {coordinateStrArr.push(client);}
              if(totalEngagementRevenuePerHour) {coordinateStrArr.push(totalEngagementRevenuePerHour);}

              return coordinateStrArr;
          },
        },
      },
      annotation: {
        annotations: {
          //Median line with shaded quartiles and labels
          upperQuartile: {
            type: "box",
            yMin: centerQuartileLineValue,
            yMax: upperQuartileValue,
            backgroundColor: centerQuartileLineValue == 0 ? "transparent" : "rgba(76, 175, 178, 0.27)",
            borderColor: centerQuartileLineValue == 0 ? "transparent" : "rgba(76, 175, 178, 0.27)",
          },
          lowerQuartile: {
            type: "box",
            yMin: lowerQuartileValue,
            yMax: centerQuartileLineValue,
            backgroundColor: "rgba(76, 175, 178, 0.67)",
            borderColor: "rgba(76, 175, 178, 0.67)",
          },
          medianLine: {
            type: "line",
            yMin: upperQuartileValue,
            yMax: upperQuartileValue,
            border: "1px solid #595959",
            label: {
              display: true,
              position: "end",
              backgroundColor: "transparent",
              color: "black",
              yAdjust: -6,
              font: {
                family: "Open Sans",
                size: 12,
                style: "normal",
                weight: 600,
                alignText: "top",
              },
              content: ["EM% Floor: " + upperQuartileValue + "%"],
            },
          },
        },
      },
    },
  };
  const marginNoDataOptions: any = {
    //maintainAspectRatio: false,
    scales: {
      //x-axis formatting
      x: {
        beginAtZero: true,
        ticks: {
          // Include a percentage sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return "$" + round(value * 10, 2) + "M";
          },
        },
        title: {
          display: true,
          align: "center",
          text: xlabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: false,
        },
      },
      //y-axis formatting
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            return round(value, 2) + "%";
          },
        },
        title: {
          display: true,
          align: "center",
          text: ylabel,
          color: "rgb(0,0,0)",
          font: {
            family: "Open Sans",
            size: 12,
            style: "normal",
            weight: 600,
          },
          padding: 4,
        },
        grid: {
          borderColor: "#D9D9D9",
          color: "#D9D9D9",
          borderWidth: 1,
          lineWidth: 1,
          display: true,
        },
      },
    },
    plugins: {
      autocolors: false,
      legend: {
        display: false,
        align: "end",
        labels: {
          boxWidth: 12,
          boxHeight: 12,
        },
      },
      tooltip: {
        enabled: true,
        displayColors: false,
        backgroundColor: "#007CB0",
        bodyFontColor: "#FFFFFF",
        bodyFont: {
          family: "Open Sans",
          size: 12,
          style: "normal",
          weight: 400,
        },
        cornerRadius: 0,
        bodySpacing: 4,
        padding: 8,
        callbacks: {
          label: (context: any) => {
            return [
              "Margin % : " + round(context.raw.y, 1) + "%",
              "NSR : $" + round(context.raw.x, 1),
            ];
          },
        },
      },
      annotation: {
        annotations: {
          //Median line with shaded quartiles and labels
          upperQuartile: {
            type: "box",
            yMin: centerQuartileLineValue,
            yMax: upperQuartileValue,
            backgroundColor: centerQuartileLineValue == 0 ? "transparent" : "rgba(76, 175, 178, 0.27)",
            borderColor: centerQuartileLineValue == 0 ? "transparent" : "rgba(76, 175, 178, 0.27)",
          },
          lowerQuartile: {
            type: "box",
            yMin: lowerQuartileValue,
            yMax: centerQuartileLineValue,
            backgroundColor: "rgba(76, 175, 178, 0.67)",
            borderColor: "rgba(76, 175, 178, 0.67)",
          },
          medianLine: {
            type: "line",
            yMin: upperQuartileValue,
            yMax: upperQuartileValue,
            border: "1px solid #595959",
            label: {
              display: true,
              position: "end",
              backgroundColor: "transparent",
              color: "black",
              yAdjust: -6,
              font: {
                family: "Open Sans",
                size: 12,
                style: "normal",
                weight: 600,
                alignText: "top",
              },
              content: ["EM% Floor: " + upperQuartileValue + "%"],
            },
          },
        },
      },
    },
  };
  const handleTimeFrameRange: MenuProps["onClick"] = (e) => {
    setTimeFrameValue(e.keyPath[0]);
  };
  const timeFrameDropdownMenu = (
    <Menu
      onClick={handleTimeFrameRange}
      items={[
        {
          label: "13 period rolling",
          key: "13 period rolling",
        },
        {
          label: "Last 4 months",
          key: "Last 4 months",
        },
        {
          label: "10 most recent",
          key: "10 most recent",
        },
      ]}
    />
  );
  const handleNsrRange: MenuProps["onClick"] = (e) => {
    setNsrRangeValue(e.keyPath[0]);
  };
  const nsrRangeDropdownMenu = (
    <Menu
      onClick={handleNsrRange}
      items={
        [
          {
            label: "All",
            key: "All",
          },
          ...Object.keys(nsrValueOptions)?.map((i: any) => {
            return {
              label: i,
              key: i,
            };
          }),
        ] || []
      }
    />
  );

  const handleHighlight: MenuProps["onClick"] = (e) => {
    setHighLightValue(e.keyPath[0]);
  };


  const highlightDropdownMenuOnJo = (
    <Menu
      onClick={handleHighlight}
      items={[
        {
          label: "Select",
          key: "Select",
        },
        {
          label: "Same Sector",
          key: "Same Sector",
        },
        {
          label: "Fixed Fee",
          key: "Fixed Fee",
        },
        {
          label: "Time & Material",
          key: "Time & Material",
        },
        {
          label: "New Engagements",
          key: "New Engagements",
        },
        {
          label: "Recent Wins",
          key: "Recent Wins",
        }
      ]}
    />
  );

  const highlightDropdownMenu = (
    <Menu
      onClick={handleHighlight}
      items={[
        {
          label: "Select",
          key: "Select",
        },
        {
          label: "Fixed Fee",
          key: "Fixed Fee",
        },
        {
          label: "Time & Material",
          key: "Time & Material",
        },
        {
          label: "New Engagements",
          key: "New Engagements",
        },
        {
          label: "Recent Wins",
          key: "Recent Wins",
        }
      ]}
    />
  );

  useEffect(() =>{
    if(highlightValue == "Same Sector" && searching == false){
      setHighLightValue("Select");
    }
  },[searching])



  return (
    <div className="chart-container">
      <div className="chart-dropdown">
        <div className="chart-dropdown-one">
          <div className="chart-dropdown-timeframe">
            <DropDown
              value={timeFrameValue || "13 period rolling"}
              menu={timeFrameDropdownMenu}
              placeholder="Timeframe"
            />
          </div>
          <div className="chart-dropdown-nsr">
            <DropDown
              value={nsrRangeValue || "All"}
              menu={nsrRangeDropdownMenu}
              placeholder="NSR Range"
            />
          </div>
          <div className="chart-dropdown-highlight">
            <DropDown
              value={highlightValue || "Select"}
              menu={searching == true ? highlightDropdownMenuOnJo : highlightDropdownMenu}
              placeholder="Highlight"
            />
          </div>
        </div>
      </div>
      <div className="chart-legends" data-testid="chart-legends">
        {Object.keys(nsrValueOptions).map((value: any, index: number) => {
          return (
            <span key={chartLegendColors[index]} className="chart-legend-box">
              <div
                className="color-box"
                style={{ backgroundColor: chartLegendColors[index] }}
              ></div>
              {value}
            </span>
          );
        })}
      </div>
      {apiData.datasets.length ? (
        <Scatter 
          options={marginOptions}
          data={{ datasets: apiDataWithColors }}
        />
      ) : (
        <Scatter options={marginNoDataOptions} data={{ datasets: [] }} />
      )}
    </div>
  );
}
