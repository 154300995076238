import { useNavigate } from "react-router-dom";
import fetchIntercept from "fetch-intercept";
import { Tooltip } from "antd";
import "./userProfile.css";
import { Endpoints } from "../../../shared/enums/endpoints";
import {
    fetchAPI
  } from "../../../shared/custom-hooks/fetchAPI";
import { useEffect, useState } from "react";
import { msalConfig } from "../../../authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

const UserProfile = ({}) => {
	const navigate = useNavigate();
    // const [fullName, setFullName] = useState("");
    // const email = sessionStorage.getItem("userEmail");
    
    // const getLoggedInUserData = async() => {
    //     const data: any = await fetchAPI(
    //         `${process.env.REACT_APP_BASE_URL}${Endpoints.getUserByEmail}`,
    //         {
    //           method: "GET",
    //           queryParam: `email_id=${email}`,
    //           headers: {}
    //         }
    //       );
          
    //       if (!data?.errMsg)
    //         setFullName(data.responseData.data.user.first_name + " " + data.responseData.data.user.last_name);
    // }

    // useEffect(() =>{
    //     getLoggedInUserData();
    // }, [])

	const handleClick = () => {
        
        const msalInstance = new PublicClientApplication(msalConfig);
        //const loginRequest = {   scopes: ["User.ReadWrite"], };
        
        const account = msalInstance.getActiveAccount();
        msalInstance.logoutRedirect({ account: account });
        
        
        fetchIntercept.clear();
        sessionStorage.clear();
        
    } 
    const preferred_name_user = sessionStorage.getItem('preferred_name_user');

	return (
        <div>
            <div className="dropdown-logout">
                <Tooltip title={"User"} placement="left">
                <span className="custom-span-logout">{ preferred_name_user  ? 
                preferred_name_user : sessionStorage.getItem('userName') }</span>
                </Tooltip>
                <div className="dropdown-content-logout">
                    <span id="spanLogout" onClick={handleClick}><i className="fa fa-sign-out"></i> &nbsp;Logout</span>
                </div>
            </div>
        </div>
	);
}
export default UserProfile;