import React, { useState, useEffect } from "react";
import Guidance from "../common/guidance/Guidance";
import Ribbon from "../common/ribbon/Ribbon";
import WelcomeDialog from "../common/welcomeDialog/WelcomeDialog";
import Label from "../common/input/Label";
import "./index.css";
import Dashboard from "../common/dashboard/Dashboard";
import DropdownEditableComponent from "./DropdownEditableComponent";
import { user } from "../../shared/interfaces/common";
import { homePage } from "../../shared/enums/homePageEnum";
import {
  marketOfferingResponse,
  offeringPortfolioResponse,
  offeringResponse,
} from "../../shared/interfaces/HomePageInterfaces";
import SelectDropdowns from "../common/homePageCards/SelectDropdowns";
import { oneMillion } from "../../shared/config/Constants";
import { setLocalStorage } from "../../shared/utils/primaryUserUtil";
import ExpertDirectory from "../common/expertDirectory/ExpertDirectory";
import { expertDirectoryContactInterface } from "../../shared/interfaces/expertDirectoryInterface";
import CommonButton from "../common/input/CommonButton";
import { useNavigate, useLocation } from "react-router-dom";

const Primary = ({ userName, userEmail, isAdmin, isLeader }: user) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [searching, setSearching] = React.useState<boolean>(false);
  const [isFirstLoad, setIsFirstLoad] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [offeringPortfolio, setOfferingPortfolio] = React.useState<string>("");
  const preferredName = sessionStorage.getItem('preferred_name_user') ? sessionStorage.getItem('preferred_name_user') : userName
  const titleText =
    "Welcome " + preferredName + " !";
  const [offering, setOffering] = React.useState<string>("");
  const [marketOffering, setMarketOffering] = React.useState<string>("");
  const [marketOfferingDetails, setMarketOfferingDetails] = React.useState<
    marketOfferingResponse[]
  >([]);
  // const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [directoryAreas, setDirectoryAreas] = React.useState<any[]>([]);
  const [directoryTopics, setDirectoryTopics] = React.useState<any[]>([]);
  const [directoryContacts, setDirectoryContacts] =
    React.useState<expertDirectoryContactInterface | null>(null);
  const [offeringPortfolioDetails, setOfferingPortfolioDetails] =
    React.useState<offeringPortfolioResponse[]>([]);
  const [offeringDetails, setOfferingDetails] = React.useState<
    offeringResponse[]
  >([]);
  const [selectedMOData, setSelectedMOData] = useState<any>(null);
  const [searchMercuryApiData, setSearchMercuryApiData] = React.useState<any>();
  const[joId, setJoId] = useState("");
 
  const {search} = useLocation();

  const urlparams = new URLSearchParams(search);
  const jupiterId = urlparams.get("jo");
  
  useEffect(() =>{
	if(sessionStorage.getItem("dashboardCall") == "false") {
		window.analyticsDataLayer = {
			pageInfo:{
			  pageName:"Dashboard",
			  userID: sessionStorage.getItem('userName'),
			  applicationName: "Strategic Pricing Portal",
			  userType: sessionStorage.getItem("userType"),
			}
		  };
		  if(typeof window._satellite !== 'undefined'){
			window._satellite.track('pageView');
		  }
		sessionStorage.setItem("dashboardCall", "true");
		sessionStorage.setItem("adminCall", "false");
		sessionStorage.setItem("userManagementCall", "false");
	}
	if(sessionStorage.getItem("fromCP3") == "" || sessionStorage.getItem("fromCP3") == null){
		if(jupiterId)
		{
			setJoId(jupiterId);
			sessionStorage.setItem("fromCP3","true");
			sessionStorage.setItem("isJupiterModified", "true");
		}
	}
	else{
		setJoId("");
	}
  })

  
  setLocalStorage();
  const navigate = useNavigate();
  
	const handleClick = () => navigate("/admin");
	
  const role = isAdmin ? "admin" : isLeader ? "leader" : "user";

  return (
		<>
			{userName && (
				<div style={{ background: "#ececec" }}>
					<Ribbon userEmail={userEmail} isAdmin={isAdmin} />
					<div className="page-header">
						<Label placeholder={homePage.heading} className="header-label" />
						<WelcomeDialog
							modelOpen={true}
							titleText={titleText}
							setSelectedMOData={setSelectedMOData}
							joId={joId}
							setSearchMercuryApiData={setSearchMercuryApiData}
							setSearching={setSearching}
							setOfferingPortfolio={setOfferingPortfolio}
							setOffering={setOffering}
							setOfferingPortfolioDetails={setOfferingPortfolioDetails}
							setOfferingDetails={setOfferingDetails}
							setJoId={setJoId}
							setMarketOffering={setMarketOffering}
							setMarketOfferingDetails={setMarketOfferingDetails}
						/>
					</div>
					{searching && searchMercuryApiData ? (
						<div>
							<div className="smart-card-details">
								<div className="smart-card-details-opname">
									{selectedMOData?.opportunity_name}
								</div>
							</div>
							<div className="smart-card-details">
								<span className="smart-card-details-1">
									{selectedMOData?.account_name} |{" "}
									{selectedMOData?.consulting_sector}
								</span>
								<br />
								<span className="smart-card-details-2">
									Value of $
									{(selectedMOData?.market_offering_value?.toString()).length > 6
										? Math.round(
												(selectedMOData?.market_offering_value / oneMillion) * 100
										) /
												100 +
										"M"
										: selectedMOData?.market_offering_value}
									&nbsp; | {selectedMOData?.mercury_id}
								</span>
							</div>
						</div>
						
					) : (
						""
					)}
					{searching && searchMercuryApiData ? (
						<DropdownEditableComponent
							setSelectedMOData={setSelectedMOData}
							searchMercuryApiData={searchMercuryApiData}
							// offeringPortfolio={
							//   searchMercuryApiData?.offering_portfolio?.op_name
							// }
							offeringPortfolio={selectedMOData?.offering_portfolio?.op_name}
							offering={offering}
							// offering={searchMercuryApiData?.offering?.offering_name}
							offeringCode={selectedMOData?.offering?.offering_code}
							offeringDetails={selectedMOData?.offering}
							setOfferingDetails={setOfferingDetails}
							marketOffering={marketOffering || selectedMOData?.mo?.mo_name}
							setOffering={setOffering}
							setOfferingPortfolio={setOfferingPortfolio}
							setMarketOffering={setMarketOffering}
							setMarketOfferingDetails={setMarketOfferingDetails}
							marketOfferingDetails={selectedMOData.mo}
							searching={searching}
							setSearching={setSearching}
							mof_code={selectedMOData?.mo?.mo_code}
							offeringPortfolioDetails={selectedMOData?.offering_portfolio}
						/>
					) : (
						<SelectDropdowns
							offeringPortfolio={offeringPortfolio}
							offeringPortfolioCode={
								selectedMOData?.offering_portfolio?.op_code
							}
							searchMercuryApiData={searchMercuryApiData}
							setSearchMercuryApiData={setSearchMercuryApiData}
							offeringCode={selectedMOData?.offering?.offering_code || null}
							setOfferingPortfolio={setOfferingPortfolio}
							offering={offering}
							offeringPortfolioDetails={offeringPortfolioDetails}
							offeringDetails={offeringDetails}
							setOfferingPortfolioDetails={setOfferingPortfolioDetails}
							setOfferingDetails={setOfferingDetails}
							setOffering={setOffering}
							marketOffering={marketOffering}
							setMarketOffering={setMarketOffering}
							marketOfferingDetails={marketOfferingDetails}
							setMarketOfferingDetails={setMarketOfferingDetails}
							searching={searching}
						/>
					)}
					{searching ? (
						<div className="dashboard-guidance-main">
							<Dashboard
								marketOffering={marketOffering || selectedMOData?.mo?.mo_name}
								marketOfferingId={
									marketOfferingDetails[0]?.mo_code ||
									selectedMOData?.mo?.mo_code
								}
								revenue_range={[
									marketOfferingDetails[0]?.revenue_range_xs || 0,
									marketOfferingDetails[0]?.revenue_range_s || 0,
									marketOfferingDetails[0]?.revenue_range_m || 0,
								]}
								engagement_type={
									marketOfferingDetails[0]?.engagement_type || {}
								}
								searching={searching}
								isFirstLoad = {false}
								setIsFirstLoad={setIsFirstLoad}
							/>
							<Guidance
								selectedMO={marketOfferingDetails[0] || selectedMOData?.mo}
								role={role}
								marketOffering={marketOffering}
								loggedUser={userEmail}
								offeringPortfolio={offeringPortfolioDetails[0] || selectedMOData?.offering_portfolio} 
								selectedOD={offeringDetails[0] || offeringDetails} />
						</div>
					) : (
						<div className="dashboard-guidance-main">
							<Dashboard
								marketOffering={marketOffering}
								marketOfferingId={marketOfferingDetails[0]?.mo_code}
								revenue_range={[
									marketOfferingDetails[0]?.revenue_range_xs || 0,
									marketOfferingDetails[0]?.revenue_range_s || 0,
									marketOfferingDetails[0]?.revenue_range_m || 0,
								]}
								engagement_type={
									marketOfferingDetails[0]?.engagement_type || {}
								}
								searching={searching}
								isFirstLoad = {isFirstLoad}
								setIsFirstLoad={setIsFirstLoad}
							/>
							<Guidance
								marketOffering={marketOffering}
								selectedMO={marketOfferingDetails[0] || selectedMOData?.mo}
								role={role}
								loggedUser={userEmail}
								offeringPortfolio={offeringPortfolioDetails[0] || selectedMOData?.offering_portfolio}
								selectedOD={offeringDetails[0]}
							/>
						</div>
					)}
				</div>
			)}

			<div id="div_askExpert"
				onClick={() => setShowTooltip(false)}
				className="expert-directory tooltip tooltip-without-border"
			>
				<span className="tooltiptext">Ask an Expert</span>

				<ExpertDirectory
					modalOpen={false}
					directoryContacts={directoryContacts}
					setDirectoryContacts={setDirectoryContacts}
					directoryAreas={directoryAreas}
					setDirectoryAreas={setDirectoryAreas}
					directoryTopics={directoryTopics}
					setDirectoryTopics={setDirectoryTopics}
				/>
			</div>
		</>
	);
};

export default Primary;
