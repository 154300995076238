export enum Endpoints {
  offeringPortfolio = "/op",
  offering = "/offering",
  marketOffering = "/mo",
  moGraph = "/mo-graph",
  guidance = "/guidance",
  jupiterId = "/getOpportunityDetails",
  feedback = "/feedback",
  exportFeedback = "/export_csv",
  area = "/area",
  topic = "/topic",
  user = "/user",
  roles = "/roles",
  emThreshold = "/em_threshold",
  login = "/login",
  upload = "/upload",
  getEmployeeList = "/get_employee_list",
  getUserByEmail = "/get_user_by_email",
}
