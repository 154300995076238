import { Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import { editOfferingPortfolioEnums, inputValidationError, validateResponse, addOfferingPortfolioEnums } from "../../../shared/enums/userManagementEnum";
import { iOfferingPortfolio } from "../../../shared/interfaces/userManagementInterface";
import CommonButton from "../../common/input/CommonButton";
import DropDown from "../../common/input/DropDown";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import UnEditableDropDown from "../../common/input/UnEditableDropdown";
import Loader from "../../common/loader/Loader";
import LeadDropdownSearch from "../offering/LeadsDropdown";
import UsersDropdown from "../offering/UsersDropdown";
import "./EditOfferingPortfolio.css";
import Label from "../../common/input/Label";

const { Title, Text } = Typography;

const EditOfferingPortfolio = ({
  editOPModalOpen,
  setEditOPModalOpen,
  offeringPortfolioData,
  getOfferingPortfolioData,
  currentPage,
}: iOfferingPortfolio) => {
  const [form] = Form.useForm();
  const [valueChanged, setValueChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [leadersData, setLeadersData] = useState<any>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [OPNameMenuItems, setOPNameMenuItems] = useState([]);
  const [OPItemsDetails, setOPItemDetails] = useState<any>([]);
  const [validateLeadandCoo, setValidateLeadandCoo] = useState(false);

  const [offeringPortfolioEditDetails, setOfferingPortfolioEditDetails] =
    useState({
      op_code: "",
      op_name: "",
      op_coo: "",
      op_coo_delegate: "",
      op_coo_id: "",
      // op_coo_delegate_id: "",
      op_leader: ""
    });

  useEffect(() => {
    setOfferingPortfolioEditDetails({
      op_code: offeringPortfolioData?.op_code || "",
      op_name: offeringPortfolioData?.op_name || "",
      op_coo: offeringPortfolioData?.op_coo_user?.preferred
        ? offeringPortfolioData?.op_coo_user?.preferred : "",
      op_coo_delegate: offeringPortfolioData?.op_coo_delegate_user?.preferred
        ? offeringPortfolioData?.op_coo_delegate_user?.preferred: "",
      op_coo_id: offeringPortfolioData?.op_coo_user?.id.toString() || "",
      // op_coo_delegate_id:
      //   offeringPortfolioData?.op_coo_delegate_user?.id?.toString() || "",
      op_leader: offeringPortfolioData?.op_leader_user?.preferred ? 
        offeringPortfolioData?.op_leader_user?.preferred : "",
    });
    callApi();
  }, [editOPModalOpen]);

  useEffect(() => {
    if(offeringPortfolioEditDetails?.op_leader && offeringPortfolioEditDetails?.op_coo && valueChanged){
      const lead = leadersData.find((item: any) => {
        return item.text == offeringPortfolioEditDetails.op_leader;
      });
      let coo: any = "";
      const cooVal = leadersData?.find((item: any) => {
        return item.text == offeringPortfolioEditDetails?.op_coo;
      });
       if(lead != undefined && lead!= null && lead.value == cooVal.value){
         setValidateLeadandCoo(true);
       }
       else{
         setValidateLeadandCoo(false);
       }
    }
  }, [offeringPortfolioEditDetails?.op_leader, offeringPortfolioEditDetails?.op_coo]);

  useEffect(() => {
    if (
			offeringPortfolioEditDetails?.op_name.trim() != "" &&
			offeringPortfolioEditDetails?.op_name.trim().length >= 2 &&
			offeringPortfolioEditDetails?.op_coo !== "" && 
      offeringPortfolioEditDetails.op_leader != ""
		) {
			setValidated(true);
		} else {
			setValidated(false);
		}
  }, [offeringPortfolioEditDetails]);

  const updateOfferingPortfolioDetails = async () => {
    setLoading(true);
    const lead = leadersData.find((item: any) => {
      return item.text == offeringPortfolioEditDetails.op_leader;
    });
    
    // let delegate: any = "";
    // const val = leadersData?.find((item: any) => {
    //   return item.text == offeringPortfolioEditDetails?.op_coo_delegate;
    // });
    // delegate = val?.value || "";

    const coo = leadersData.find((item: any) => {
      return item.text == offeringPortfolioEditDetails.op_coo;
    });

    const getBody = () => {
      return {
        op_code: offeringPortfolioData?.op_code,
        op_name: offeringPortfolioEditDetails?.op_name.trim(),
        op_coo: coo.value,
        op_leader: lead.value
      };

      // if (delegate === "") {
      //   return {
      //     op_code: offeringPortfolioData?.op_code,
      //     op_name: offeringPortfolioEditDetails?.op_name,
      //     op_coo: lead.value || offeringPortfolioEditDetails?.op_coo_id,
      //   };
      // } else {
      //   return {
      //     op_code: offeringPortfolioData?.op_code,
      //     op_name: offeringPortfolioEditDetails?.op_name,
      //     op_coo: lead.value || offeringPortfolioEditDetails?.op_coo_id,
      //     op_coo_delegate:
      //       delegate || offeringPortfolioEditDetails?.op_coo_delegate_id,
      //   };
      // }
    };
    try {
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        queryParam: "op_code=" + offeringPortfolioData?.op_code,
        body: JSON.stringify(getBody()),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${addOfferingPortfolioEnums.OPNameExists}`);
        }
        else {
          SuccessMessage(
            `Offering Portfolio ${offeringPortfolioEditDetails?.op_name} updated successfully.`
          );
          getOfferingPortfolioData(currentPage);
        }
      } else {
        ErrorMessage("Something went wrong. Please try again later");
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
    setLoading(false);
  };

  let options = {
    headers: {}
  }

  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.preferred,
        }));
        setLeadersData(datas);
      });
  };

  const handleOk = () => {
    updateOfferingPortfolioDetails();
    setValueChanged(false);
    setError("");
    setEditOPModalOpen(false);
  };
  const handleCancel = () => {
    setOfferingPortfolioEditDetails({
      op_code: "",
      op_name: "",
      op_coo: "",
      op_coo_delegate: "",
      op_coo_id: "",
      // op_coo_delegate_id: "",
      op_leader: ""
    });
    setError("");
    setValueChanged(false);
    setEditOPModalOpen(false);
  };

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setValueChanged(true);
      setOfferingPortfolioEditDetails({
        ...offeringPortfolioEditDetails,
        op_name: e.target.value,
      });
    }
  };

  // const setOfferingPortfolioLeadId = (val: any) => {
  //   setValueChanged(true);
  //   setOfferingPortfolioEditDetails({
  //     ...offeringPortfolioEditDetails,
  //     op_coo_id: val,
  //   });
  // };
  // const setOfferingPortfolioDelegateLeadId = (val: any) => {
  //   setValueChanged(true);
  //   setOfferingPortfolioEditDetails({
  //     ...offeringPortfolioEditDetails,
  //     op_coo_delegate_id: val,
  //   });
  // };

  const setOfferingPortfolioLead = (val: any) => {
    setValueChanged(true);
    setOfferingPortfolioEditDetails({
      ...offeringPortfolioEditDetails,
      op_leader: val,
    });
  };
  // const setOfferingPortfolioDelegateLead = (val: any) => {
  //   setValueChanged(true);
  //   setOfferingPortfolioEditDetails({
  //     ...offeringPortfolioEditDetails,
  //     op_coo_delegate: val,
  //   });
  // };

  const setOfferingPortfolioCoo = (val: any) => {
    setValueChanged(true);
    setOfferingPortfolioEditDetails({
      ...offeringPortfolioEditDetails,
      op_coo: val,
    });
  };

  const setOfferingLeadVal = (val: string) =>{     }
  //const setOfferingDelegateVal = (val: string) =>{ }
  const setOfferingCooVal = (val: string) =>{ }

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [editOPModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
		<>
			<Modal
				open={editOPModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Loader isLoading={loading} fullPage={false}>
					<Title level={5}>{editOfferingPortfolioEnums.heading}</Title>
					<Text>{editOfferingPortfolioEnums.subHeading}</Text>
					<Form
						form={form}
						layout="vertical"
						className="edit-role-modal-form"
						fields={[
							{
								name: ["offering_name"],
								value: offeringPortfolioEditDetails.op_name,
							},
						]}
					>
						<div className="edit-role-modal-form-row">
							<span className="edit-role-row-item-1">
								<UnEditableDropDown
									placeholder={editOfferingPortfolioEnums.OPcodeLabel}
									value={offeringPortfolioData?.op_code || "--"}
								/>
							</span>
							<span className="edit-role-row-item-1">
								<Form.Item
									name="offering_name"
									label="Offering Portfolio Name *"
									// rules={[
									// 	{
									// 		whitespace: true,
									// 		message: "Offering Portfolio Name cannot be empty.",
									// 	},
									// 	{
									// 		validator: (_, value) =>
									// 			value.trim().length >= 2
									// 				? Promise.resolve()
									// 				: Promise.reject(
									// 						new Error(inputValidationError.min2Character)
									// 				  ),
									// 	},
									// 	{
									// 		max: 500,
									// 		message: inputValidationError.max500Characters,
									// 		type: "string",
									// 	},
									// ]}
									// key={offeringPortfolioData?.op_code}
								>
									<TextField
										onChange={handleInputChange}
										value={offeringPortfolioEditDetails.op_name}
										placeholder="Enter Offering Portfolio Name"
										className="offering-textfield"
										name="offering_name"
                    maxLength={100}
									/>
								</Form.Item>
							</span>
						</div>
						<div className="edit-role-modal-form-row">
            <span className="edit-role-row-item-2">
								<Form.Item>
									<Text>Offering Portfolio Lead *</Text>
									<UsersDropdown
										setInitialVal={setOfferingPortfolioLead}
										initialVal={offeringPortfolioEditDetails.op_leader}
                    setSelectedVal={setOfferingLeadVal}
									/>
                  {validateLeadandCoo == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Offering Lead and COO cannot be same."} />
                )}
								</Form.Item>
							</span>
							<span className="edit-role-row-item-2">
								<Form.Item>
									<Text>Offering Portfolio COO *</Text>
									{/* <LeadDropdownSearch
										setInitialVal={setOfferingPortfolioDelegateLead}
										initialVal={offeringPortfolioEditDetails.op_coo_delegate}
                    setSelectedVal={setOfferingDelegateVal}
									/> */}
                  <LeadDropdownSearch
										setInitialVal={setOfferingPortfolioCoo}
										initialVal={offeringPortfolioEditDetails.op_coo}
                    setSelectedVal={setOfferingCooVal}
									/>
								</Form.Item>
							</span>
						</div>
						<Form.Item className="edit-role-row-btn">
							<div className="edit-role-btn-1" data-testid="cancel-btn">
								<CommonButton
									value="Cancel"
									onClick={handleCancel}
									color="#FFFFFF"
									fontColor="rgba(0, 0, 0, 0.85)"
									border="1px solid #D9D9D9"
								/>
							</div>
							<CommonButton
								value="Update"
								onClick={handleOk}
								disabled={!validated || !valueChanged || validateLeadandCoo}
							/>
						</Form.Item>
					</Form>
				</Loader>
			</Modal>
		</>
	);
};

export default EditOfferingPortfolio;
