import React, { useEffect, useState } from "react";
import "./Footer.css";

const Footer = () => {
    return (<div className="footer-wrapper">
    <p>Copyright ©️ {new Date().getFullYear()} Deloitte LLC. All rights reserved.&nbsp; </p>
    <a href="https://cookienotice.deloitte.com/" target="_blank"> Cookies </a> 
    &nbsp;|&nbsp;
    <a className="link optanon-toggle-display">Cookie Settings</a>
    &nbsp;|&nbsp;
    <a href="https://www2.deloitte.com/us/en/footerlinks1/web-privacy-notice.html" target="_blank"> Privacy Notice </a> 
    </div>)
}

export default Footer;