import { Button, Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import CommonButton from "../../common/input/CommonButton";
import Label from "../../common/input/Label";
import "./EditOffering.css";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import { ListFormat } from "typescript";
import LeadsDropdown from "./LeadsDropdown";
import OpDropdown from "./OpDropdown";
import { inputValidationError, validateResponse, addOfferingEnums } from "../../../shared/enums/userManagementEnum";
const { Title, Text } = Typography;

const AddOffering = ({
  addOfferingModalOpen,
  setAddOfferingModalOpen,
  offeringData,
  getOfferingData,
  setCurrentPage,
}: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validation, setValidation] = useState(false);
  const [leadersData, setLeadersData] = useState<any>([]);
  const [oPData, setOpData] = useState<any>([]);
  const [validateLeadandDelegate, setValidateLeadandDelegate] = useState(false);
  const [offering_lead_id, setOffering_lead_id] = useState<string>("");
  const [offering_delegate_id, setOffering_delegate_id] = useState<string>("");
  const [offeringAddDetails, setOfferingAddDetails] = useState({
    offering_code: "",
    op_code: "",
    op_name: "",
    offering_name: "",
    offering_lead: "",
    offering_delegate: "",
    offering_lead_id: "",
    offering_delegate_id: "",
  });
  const [valueChanged, setValueChanged] = useState({
    offering_code: false,
    offering_name: false,
    op_code: false,
    offering_lead: false,
  });
  useEffect(() => {
    callApi();
    callApiOP();
  }, [addOfferingModalOpen]);
  useEffect(() => {
    if (
			offeringAddDetails.offering_code.trim() != "" &&
			offeringAddDetails.op_name != "" &&
			offeringAddDetails?.offering_name.trim() != "" &&
			offeringAddDetails?.offering_name.trim().length >= 2 &&
			offeringAddDetails.offering_lead != "" &&
      offering_lead_id != offering_delegate_id
		) {
			setValidation(true);
		} else {
			setValidation(false);
		}
  }, [valueChanged]);
  const options = {
    headers: {}
  }
  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}?role_id=3`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.preferred,
        }));
        setLeadersData(datas);
      });
  };
  const callApiOP = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.offeringPortfolio}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.op_code,
          text: item.label,
        }));
        setOpData(datas);
      });
  };
  const handleOk = () => {
    addOfferingDetails();
    setCurrentPage(1);

    setValueChanged({
      offering_code: false,
      offering_name: false,
      op_code: false,
      offering_lead: false,
    });
    setError("");
    setAddOfferingModalOpen(false);
    setLeadersData("");
  };
  const handleCancel = () => {
    setError("");
    setLeadersData("");
    setValueChanged({
      offering_code: false,
      offering_name: false,
      op_code: false,
      offering_lead: false,
    });
    setAddOfferingModalOpen(false);
  };

  const handleCodeChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setValueChanged({
        ...valueChanged,
        offering_code: true,
      });
      setOfferingAddDetails({
        ...offeringAddDetails,
        offering_code: e.target.value,
      });
    }
  };
  

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z0-9 &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setValueChanged({
        ...valueChanged,
        [e.target.name]: true,
      });
      setOfferingAddDetails({
        ...offeringAddDetails,
        [e.target.name]: e.target.value,
      });
    }
  };

  const setOfferingLeadVal = (val: string) =>{
    setOffering_lead_id(val);
    setValidateLeadandDelegate(val == offering_delegate_id ? true: false);
  }

  const setOfferingDelegateVal = (val: string) =>{
    setOffering_delegate_id(val);
    setValidateLeadandDelegate(val == offering_lead_id ? true : false);
  }

  const setOfferingLead = (val: any) => {
    setValueChanged({
      ...valueChanged,
      offering_lead: true,
    });
    setOfferingAddDetails({
      ...offeringAddDetails,
      offering_lead: val,
    });
    
  };
  const setOfferingDelegate = (val: any) => {
    setValueChanged({
      ...valueChanged,
      offering_lead: true,
    });
    setOfferingAddDetails({
      ...offeringAddDetails,
      offering_delegate: val,
    });
  };

  const setOfferingPortfolio = (val: any) => {
    setValueChanged({
      ...valueChanged,
      op_code: true,
    });
    setOfferingAddDetails({
      ...offeringAddDetails,
      op_name: val,
    });
  };
  const getBody = (lead: any, delegate: any, op: any) => {
    if (delegate == "") {
      return {
        offering_code: parseInt(offeringAddDetails?.offering_code),
        offering_name: offeringAddDetails?.offering_name.trim(),
        op_code: op,
        offering_lead: lead,
      };
    } else
      return {
        offering_code: parseInt(offeringAddDetails?.offering_code),
        offering_name: offeringAddDetails?.offering_name.trim(),
        op_code: op,
        offering_lead: lead,
        offering_delegate: delegate,
      };
  };

  const addOfferingDetails = async () => {
    const lead = leadersData.find((item: any) => {
      return item.text == offeringAddDetails.offering_lead;
    });
    let delegate: any = "";
    const val = leadersData?.find((item: any) => {
      return item.text == offeringAddDetails?.offering_delegate;
    });
    delegate = val?.value || "";
    const op = oPData.find((item: any) => {
      return item.text == offeringAddDetails.op_name;
    });

    try {
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(getBody(lead.value, delegate, op.value)),
      };
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${addOfferingEnums.OfferingNameExists}`);
        }
        else {
          SuccessMessage(`Offering ${offeringAddDetails.offering_name} added successfully.`);
          getOfferingData();
        }
      } else {
        if(data.responseData.message.includes(`${validateResponse.already}`)){
          ErrorMessage(`${addOfferingEnums.OfferingCodeExists}`);
        }
        else{
          ErrorMessage("Something went wrong. Please try again later");
        }
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [addOfferingModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  return (
		<>
			<Modal
				open={addOfferingModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Form
					onFinish={handleOk}
					form={form}
					layout="vertical"
					className="edit-offering-form"
					fields={[
						{
							name: ["offering_name"],
							value: offeringAddDetails.offering_name,
						},
					]}
				>
					<Title level={5}>Add Offering</Title>
					<Text>This will help you to add an offering in the framework.</Text>
					<div className="admin-offering-input-main">
						<div className="admin-offering-row">
							<div className="offering-input">
								<Text>Offering Code *</Text>
								<TextField
									name="offering_code"
									onChange={handleCodeChange}
									value={offeringAddDetails.offering_code}
									className="offering-textfield"
                  maxLength={8}
								/>
							</div>
							<div className="offering-input">
								<Text>Offering Portfolio *</Text>

								<OpDropdown
									initialVal={offeringAddDetails.op_name}
									setInitialVal={setOfferingPortfolio}
								/>
							</div>
						</div>
						<div className="admin-offering-row">
							<div className="offering-input">
								<Form.Item
									name="offering_name"
									label="Offering Name *"
									// rules={[
									// 	{
									// 		whitespace: true,
									// 		message: "Offering Name cannot be empty.",
									// 	},
									// 	{
									// 		validator: (_, value) =>
									// 			value.trim().length >= 2
									// 				? Promise.resolve()
									// 				: Promise.reject(
									// 						new Error(inputValidationError.min2Character)
									// 				  ),
									// 	},
									// 	{
									// 		max: 500,
									// 		message: inputValidationError.max500Characters,
									// 		type: "string",
									// 	},
									// ]}
									// key={offeringAddDetails?.offering_code}
								>
									<TextField
										onChange={handleInputChange}
										value={offeringAddDetails.offering_name}
										placeholder="Enter Offering Name"
										className="offering-textfield"
										name="offering_name"
                    maxLength={100}
									/>
								</Form.Item>
							</div>
							<div className="offering-input">
								<Text>Offering Lead *</Text>
								<LeadsDropdown
									initialVal={offeringAddDetails.offering_lead}
									setInitialVal={setOfferingLead}
                  setSelectedVal={setOfferingLeadVal}
								/>
							</div>
						</div>
						<div className="admin-offering-row">
							<div className="offering-input">
								<Text>Offering Delegate</Text>
								<LeadsDropdown
									initialVal={offeringAddDetails.offering_delegate}
									setInitialVal={setOfferingDelegate}
                  setSelectedVal={setOfferingDelegateVal}
								/>
                {validateLeadandDelegate == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Offering Lead and Delegate cannot be same."} />
                )}
                
							</div>

						</div>
					</div>
					<div className="admin-offering-buttons">
						<CommonButton
							value="Cancel"
							onClick={handleCancel}
							color="#FFFFFF"
							fontColor="rgba(0, 0, 0, 0.85)"
							border="1px solid #D9D9D9"
						/>
						<CommonButton
							value="Add Offering"
							onClick={handleOk}
							disabled={!validation}
							className="save-button"
						/>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default AddOffering;
