// import React from 'react';
import "./Ribbon.css";
import { Layout, Tooltip } from "antd";
import Logo from "../../../assets/logo.png";
import Dot from "../../../assets/green.dot.png";
import Star from "../../../assets/Vector.png";
import FeedBack from "../feedBack/FeedBack";
import { useState } from "react";
import { FeedBackEnum } from "../../../shared/enums/feedBackEnums";
import SettingsMenu from "../settingsMenu/SettingsMenu";
import UserProfile from "../userProfile/userProfile";
interface iRibbon {
  userEmail: string;
  isAdmin?: boolean;
}
const Ribbon = ({ userEmail, isAdmin }: iRibbon) => {
  const { Header } = Layout;
  const [isFeedBackOpen, setIsFeedBackOpen] = useState(false);

  return (
		<>
			<Layout className="ribbon-layout">
				<Header className="header">
					<div className="logo">
						<img
							src={Logo}
							alt="logo"
							className="logo-image"
							data-testid="logo"
						/>
						<img src={Dot} alt="dot" className="dot" />
					</div>
					<div className="div-information-header">
						<div className="star">
							<Tooltip title={FeedBackEnum.toolTip} placement="left">
								<img
									src={Star}
									alt="star"
									className="star-image"
									data-testid="star"
									onClick={() => setIsFeedBackOpen(true)}
								/>
							</Tooltip>
							<FeedBack
								isFeedBackOpen={isFeedBackOpen}
								setIsFeedBackOpen={setIsFeedBackOpen}
								userEmail={userEmail}
							/>
						</div>
						{ isAdmin && <SettingsMenu></SettingsMenu>}
						<UserProfile></UserProfile>
					</div>
				</Header>
			</Layout>
		</>
	);
};

export default Ribbon;
