import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { chartLabels } from "../../../shared/enums/chartEnums";
import { Endpoints } from "../../../shared/enums/endpoints";
import { iNsrRangeOptions } from "../../../shared/interfaces/chartInterface";
import { chartLegendColors } from "../../../shared/enums/chartEnums";
import {
  iapiDataGraph,
  imarginChartData,
  irphChartData,
} from "../../../shared/interfaces/graphInterface";
import { iEngagementType } from "../../../shared/interfaces/HomePageInterfaces";
import {
  alterKMValues,
  getLabelWithKeys,
  queryParams,
  rhpXaxisParam,
} from "../../../shared/utils/graphUtil";
import ErrorPage from "../errorPage/ErrorPage";
import Label from "../input/Label";
import Loader from "../loader/Loader";
import "./ChartBase.css";
import { EmptyChart } from "./EmptyChart";
import { MarginChart } from "./MarginChart";
import { RphChart } from "./RphChart";
import { serialize } from "v8";
import { useLocation } from "react-router-dom";
interface MarketOfferingInterface {
  marketOffering: string | undefined;
  marketOfferingId: number | undefined;
  revenue_range: number[];
  revenue_range_m: number;
  engagement_type: iEngagementType;
  searching: boolean | false;
  isFirstLoad:boolean | true;
  setIsFirstLoad : any
}

const ChartBase = ({
  marketOffering,
  marketOfferingId,
  revenue_range,
  revenue_range_m,
  engagement_type,
  searching,
  isFirstLoad,
  setIsFirstLoad
}: MarketOfferingInterface) => {
  const [chartType, setChartType] = useState("rphChart");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [graphSearchedMOId, setGraphSearchedMOId] = useState(0);
  const [rphApidata, setRphApiData] = useState<iapiDataGraph>({
    datasets: [],
  });
  const [marginApidata, setMarginApiData] = useState<iapiDataGraph>({
    datasets: [],
  });
  // TODO:Add types for all useState
  const [rphChartData, setRphChartData] = useState<irphChartData | null>(null);
  const [marginChartData, setMarginChartData] =
    useState<imarginChartData | null>(null);
  const [labelValues, setLabelValues] = useState({
    centerQuartileLineValue: 0,
    lowerQuartileValue: 0,
    upperQuartileValue: 0,
  });
  const [rphLabelValues, setRphLabelValues] = useState({
    centerQuartileLineValue: 0,
    lowerQuartileValue: 0,
    upperQuartileValue: 0,
  });
  const [marginLabelValues, setMarginLabelValues] = useState({
    centerQuartileLineValue: 0,
    lowerQuartileValue: 0,
    upperQuartileValue: 0,
  });
  const [colorBands, setColorBands] = useState<boolean>(false);
  const [timeFrameValueRph, setTimeFrameValueRph] =
    useState<string>("13 period rolling");
  const [timeFrameValueMargin, setTimeFrameValueMargin] =
    useState<string>("13 period rolling");
  const [rphXaxisValue, setRphXaxisValue] = useState<string>("% USI Offshore");
  const [nsrRangeRph, setNarRangeRph] = useState<string>("All");
  const [nsrRangeMargin, setNsrRangeMargin] = useState<string>("All");
  const [highLightValueRph, setHighLightValueRph] = useState<string>("Select");
  const [highLightValueMargin, setHighLightValueMargin] = useState<string>("Select");
  const [moData, setMoData] = useState<string>("");
  const [dataLoadedTwo, setDataLoadedTwo] = useState<boolean>(true);
  
  //API call for data
  const [nsrValueOptions, setNsrValueOptions] = useState<iNsrRangeOptions>({});

  const getMarginLabelValues = (bandsVisible: boolean) => {
    if(bandsVisible == true){
      setMarginLabelValues({
        centerQuartileLineValue: engagement_type?.em_th_medium || 0,
        lowerQuartileValue: engagement_type?.em_th_large || 0,
        upperQuartileValue: engagement_type?.em_th_small || 0,
      });
    }
    else {
      setMarginLabelValues({
        centerQuartileLineValue: 0,
        lowerQuartileValue: 0,
        upperQuartileValue: engagement_type?.em_th_small || 0,
      });
    }
  }

  const getRphValues = (bandsData: any) =>{
    setRphLabelValues({
      centerQuartileLineValue:
        bandsData?.additional_data.complete.median || 0,
      lowerQuartileValue:
        bandsData?.additional_data.complete.quartile.lower || 0,
      upperQuartileValue:
        bandsData?.additional_data.complete.quartile.upper || 0,
    });
  }

  useEffect(() => {
    setError("");
    if (marketOffering && revenue_range[2] !== 0) {
      if (marketOffering != moData) {

        setMoData(marketOffering);
        setChartType("rphChart");
        setRphXaxisValue("% USI Offshore");
        setTimeFrameValueRph("13 period rolling");
        setTimeFrameValueMargin("13 period rolling");
        setNarRangeRph("All");
        setNsrRangeMargin("All");
        setHighLightValueRph("Select");
        setHighLightValueMargin("Select");
        setNsrValueOptions(
          getLabelWithKeys(revenue_range, alterKMValues(revenue_range))
        );
        setColorBands(false);
        setMarginChartData(null);
        if (timeFrameValueRph == "13 period rolling" && timeFrameValueMargin == "13 period rolling"
          && highLightValueMargin == "Select" && highLightValueRph == "Select"
          && nsrRangeMargin == "All" && nsrRangeRph == "All" && rphXaxisValue == "% USI Offshore") {
          getRphChartData(true);
          setDataLoadedTwo(true);
        }
      }
      else if (marketOffering) {
        if (dataLoadedTwo == false) {
          setDataLoadedTwo(true);
          if (timeFrameValueRph != "13 period rolling" || timeFrameValueMargin != "13 period rolling"
          && highLightValueMargin != "Select" || highLightValueRph != "Select"
          && nsrRangeMargin != "All" || nsrRangeRph != "All" || rphXaxisValue != "% USI Offshore") {
          getRphChartData();
        }
        }
        else {
          if (chartType === "rphChart") getRphChartData();
          else getMarginChartData();
        }
      }

      if(isFirstLoad == true || searching == true){
        setIsFirstLoad(false);
        setDataLoadedTwo(true);
      }
    }
  }, [marketOffering, revenue_range_m,
    timeFrameValueRph,
    timeFrameValueMargin,
    rphXaxisValue,
    nsrRangeRph,
    nsrRangeMargin,
    highLightValueRph,
    highLightValueMargin,]);

  useEffect(() => {
    try {
      if (rphChartData || marginChartData) {
        if (chartType === "rphChart") {
          // setRphApiData({ datasets: rphChartData?.data?.graph_data || [] });
          getRphValues(rphChartData?.data);
        } else {
          // setMarginApiData({
          //   datasets: marginChartData?.data?.graph_data || [],
          // });
          getMarginLabelValues(colorBands);
        }
      }
      if (!marginChartData && chartType === "marginChart") {
        getMarginLabelValues(colorBands);
      }
    } catch (err) {
      setError("Error");
    }
  }, [chartType]);

  const getUpdatedGraphData = (inputGraphData: any) => {
    let i = 0;
    let dataGraph = inputGraphData.responseData.data.graph_data;
    let dataGraphModified = [];
    let innerVar = 0;
    // console.log("graph data", data.responseData.data.graph_data);
    let colorindex = -1;
    if (chartType == "rphChart") {
      if (nsrRangeRph !== "All") {
        colorindex = Object.keys(nsrValueOptions).findIndex(
          (p) => p == nsrRangeRph
        );
      }
    }
    else {
      if (nsrRangeMargin !== "All") {
        colorindex = Object.keys(nsrValueOptions).findIndex(
          (p) => p == nsrRangeMargin
        );
      }
    }

    for (i = 0; i < dataGraph.length; i++) {
      let currentLabel = dataGraph[i].label;
      let tempDataTrue: any = [];
      let tempDataFalse: any = [];
      dataGraph[i].data.forEach((element: any) => {
        if (element.highlight == true) {
          tempDataTrue.push(element);
        }
        else {
          tempDataFalse.push(element);
        }
      });
      if (tempDataTrue.length > 0) {
        dataGraphModified[innerVar] = {
          data: tempDataTrue, borderColor: "orange", borderWidth: 1.5, label: currentLabel, backgroundColor:
            colorindex === -1
              ? chartLegendColors[i]
              : chartLegendColors[colorindex]
        };
        innerVar += 1;
      }
      if (tempDataFalse.length > 0) {
        dataGraphModified[innerVar] = {
          data: tempDataFalse, label: currentLabel, backgroundColor:
            colorindex === -1
              ? chartLegendColors[i]
              : chartLegendColors[colorindex]
        };
        innerVar += 1;
      }
    }
    return dataGraphModified;
  }

  const getRphChartData = async (flag = false) => {
    setLoading(true);
    sessionStorage.setItem('dataMapped','false');
    try {
      let highlightVal = highLightValueRph;
      if (highLightValueRph == "Time & Material") {
        highlightVal = "Time and Material";
      }
      let qp = "";
      if (flag) {
        setDataLoadedTwo(false);
        highlightVal = "Select";
        qp +=
          queryParams["13 period rolling"] +
          `&x_axis=${rhpXaxisParam["% USI Offshore"]}&all_ranges=,`;
        revenue_range.forEach((element) => {
          if (element !== 0) qp += `${element}**${element},`;
        });
      } else {
        qp +=
          queryParams[timeFrameValueRph] +
          `&x_axis=${rhpXaxisParam[rphXaxisValue]}`;
        if (nsrRangeRph === "All") {
          qp += "&all_ranges=,";
          revenue_range.forEach((element) => {
            if (element !== 0) qp += `${element}**${element},`;
          });
        } else {
          qp += nsrValueOptions[nsrRangeRph];
        }
      }
      let JOID: any;

      if (searching == true) {
        JOID = sessionStorage.getItem("jupiterID");
      }
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_CHART_URL}${Endpoints.moGraph}`,
        {
          method: "GET",
          queryParam: `graph_type=rph` + "&highlight=" + highlightVal + `&jupiter_id=` + JOID + `&mo_id=${marketOfferingId}${qp?.length ? `&${qp}` : ""
            }`,
          headers: {}
        }
      );
      if (!data?.errMsg) {

        if (Object.keys(data?.responseData?.data.graph_data || {}).length) {
          setRphChartData(data?.responseData);
          if (highlightVal != "Select") {
            data.responseData.data.graph_data = getUpdatedGraphData(data);
            setRphApiData({
              datasets: data.responseData.data.graph_data,
            });
          }
          else {
            let colorindex = -1;
            if (nsrRangeRph !== "All") {
              colorindex = Object.keys(nsrValueOptions).findIndex(
                (p) => p == nsrRangeRph
              );
            }
            let rphGraphDataCopy = data?.responseData?.data.graph_data;
            const rphGraphDataCopy2 = rphGraphDataCopy.map((data: any, index: number) => {
              return {
                ...data,
                backgroundColor:
                  colorindex === -1
                    ? chartLegendColors[index]
                    : chartLegendColors[colorindex],
              };
            });

            setRphApiData({
              datasets: rphGraphDataCopy2,
            });
          }
          getRphValues(data?.responseData?.data);
        } else {
          setRphChartData(null);
          setRphApiData({
            datasets: [],
          });
          setRphLabelValues({
            centerQuartileLineValue: 0,
            lowerQuartileValue: 0,
            upperQuartileValue: 0,
          });
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      setError("Error");
    }
  };
  
  const {search} = useLocation();
  const urlparams = new URLSearchParams(search);
  const usi = urlparams.get("marginx");
  let margin:any = urlparams.get("marginy");
  const rph = urlparams.get("rphy");

  const getPotentialEngagementData = () => {
    if(sessionStorage.getItem("isJupiterModified") == "true"){
      if(margin != null){
        margin = parseFloat(margin) * 100;
      }
      let engagementData = 
          [{
            "data": [
                {
                    "x": usi,
                    "y": margin.toFixed(2),
                    "total_engagement_revenue_per_hour": rph,
                    "client_program" : "Potential Engagement",
                },
            ],
            "backgroundColor": "#008000",
            "pointRadius": 6,
            "pointHoverRadius": 7
        }];
        
        return engagementData;
    }
    else{
      return [];
    }
  }
  const getMarginChartData = async (flag = false) => {
    setLoading(true);
    try {
      let highlightVal = highLightValueMargin;
      if (highLightValueMargin == "Time & Material") {
        highlightVal = "Time and Material";
      }
      let qp = "";
      if (flag) {
        highlightVal = "Select";
        qp = queryParams["13 period rolling"];
        qp += `&all_ranges=,`;
        revenue_range.forEach((element) => {
          if (element !== 0) qp += `${element}**${element},`;
        });
      } else {
        qp = queryParams[timeFrameValueMargin];
        if (nsrRangeMargin === "All") {
          qp += "&all_ranges=,";
          revenue_range.forEach((element) => {
            if (element !== 0) qp += `${element}**${element},`;
          });
        } else {
          qp += nsrValueOptions[nsrRangeMargin];
        }
      }
      let JOID: any;

      if (searching == true) {
        JOID = sessionStorage.getItem("jupiterID");
      }

      setGraphSearchedMOId(marketOfferingId != undefined ? marketOfferingId : 0);
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_CHART_URL}${Endpoints.moGraph}`,
        {
          method: "GET",
          queryParam: `graph_type=margin` + "&highlight=" + highlightVal + `&jupiter_id=` + JOID + `&mo_id=${marketOfferingId}${qp?.length ? `&${qp}` : ""
            }`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data.graph_data || {}).length) {
          setMarginChartData(data?.responseData);
          if (highlightVal != "Select") {
            data.responseData.data.graph_data = getUpdatedGraphData(data);
            data.responseData.data.graph_data.push(...getPotentialEngagementData());
            setMarginApiData({ datasets: data?.responseData?.data.graph_data });
          }
          else {
            let colorindex = -1;
            if (nsrRangeMargin !== "All") {
              colorindex = Object.keys(nsrValueOptions).findIndex(
                (p) => p == nsrRangeMargin
              );
            }

            let marginGraphDataCopy = data?.responseData?.data.graph_data;
            const marginGraphDataCopy2 = marginGraphDataCopy.map((data: any, index: number) => {
              return {
                ...data,
                backgroundColor:
                  colorindex === -1
                    ? chartLegendColors[index]
                    : chartLegendColors[colorindex],
              };
            });

            marginGraphDataCopy2.push(...getPotentialEngagementData());
            setMarginApiData({
              datasets: marginGraphDataCopy2
            });
          }

          let colorbandsVisible = data?.responseData?.data.graph_data.some(
            (pricings: any) => {
              return pricings.data.some((pricing: any) => pricing.x > 5000000);
            }
          );

          setColorBands(colorbandsVisible);
          getMarginLabelValues(colorbandsVisible);
        }
        else {
          let potentialEngagementData:any = getPotentialEngagementData();
          setMarginChartData(null);
          setMarginApiData({
            datasets: potentialEngagementData,
          });

          getMarginLabelValues(colorBands);
        }
        setLoading(false);
        setChartType("marginChart");
      } else {
        setLoading(false);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      setError("error");
    }

  };

  const handleChartType = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.value == "marginChart") {
      if (marketOfferingId != undefined && (marketOfferingId != graphSearchedMOId || marginChartData == undefined || marginChartData == null))
        getMarginChartData(true);
      else
        setChartType(event.currentTarget.value);
    }
    else
      setChartType(event.currentTarget.value);
  };

  return (
    <>
      {error === "" ? (
        <>
          <Loader isLoading={loading} fullPage={false}>
            <div className="chart-base-main">
              <div data-testid="chartLabel-test">
                <Label
                  className="chart-base-label"
                  placeholder={
                    chartType === "rphChart"
                      ? "Rate per hour Relative To Resource Leverage Structure"
                      : "Margin % to NSR"
                  }
                />
              </div>
              <Radio.Group
                className="chart-base-group"
                defaultValue={chartType}
                buttonStyle="solid"
                data-testid="chartType-test"
              >
                <Radio.Button id="rdb_rph"
                  className="chart-base-button"
                  onClick={handleChartType}
                  value="rphChart"
                >
                  RPH
                </Radio.Button>
                <Radio.Button id="rdb_margin"
                  className="chart-base-button"
                  onClick={handleChartType}
                  value="marginChart"
                >
                  Margin %
                </Radio.Button>
              </Radio.Group>
            </div>
            <Label
              className="chart-base-sub-label"
              data-testid="sub-label-test"
              placeholder="This chart can help address the question of what the typical market price for these services is, given the resource mix."
            />
            {marketOffering && !loading ? (
              chartType === "rphChart" ? (
                <RphChart
                  xlabel={chartLabels.rphXLabelValue}
                  ylabel={chartLabels.rphYLabelValue}
                  apiData={rphApidata}
                  labelValues={rphLabelValues}
                  timeFrameValue={timeFrameValueRph}
                  setTimeFrameValue={setTimeFrameValueRph}
                  rphXaxisValue={rphXaxisValue}
                  setRphXaxisValue={setRphXaxisValue}
                  nsrValueOptions={nsrValueOptions}
                  nsrRangeValue={nsrRangeRph}
                  setNsrRangeValue={setNarRangeRph}
                  setHighLightValue={setHighLightValueRph}
                  highlightValue={highLightValueRph}
                  searching={searching}
                ></RphChart>
              ) :
                <MarginChart
                  xlabel={chartLabels.marginXLabelValue}
                  ylabel={chartLabels.marginYLabelValue}
                  apiData={marginApidata}
                  labelValues={marginLabelValues}
                  timeFrameValue={timeFrameValueMargin}
                  setTimeFrameValue={setTimeFrameValueMargin}
                  nsrValueOptions={nsrValueOptions}
                  nsrRangeValue={nsrRangeMargin}
                  setNsrRangeValue={setNsrRangeMargin}
                  setHighLightValue={setHighLightValueMargin}
                  highlightValue={highLightValueMargin}
                  searching={searching}
                ></MarginChart>
            ) : (
              <EmptyChart
                xlabel={
                  chartType === "rphChart"
                    ? chartLabels.rphXLabelValue
                    : chartLabels.marginXLabelValue
                }
                ylabel={
                  chartType === "rphChart"
                    ? chartLabels.rphYLabelValue
                    : chartLabels.marginYLabelValue
                }
                chartType={chartType}
                apiData={{ datasets: [] }}
                labelValues={labelValues}
                timeFrameValue={[timeFrameValueRph, timeFrameValueMargin]}
                setTimeFrameValue={[
                  setTimeFrameValueRph,
                  setTimeFrameValueMargin,
                ]}
                rphXaxisValue={rphXaxisValue}
                setRphXaxisValue={setRphXaxisValue}
                nsrValueOptions={{}}
                nsrRangeValue={[nsrRangeRph, nsrRangeMargin]}
                setNsrRangeValue={[setNarRangeRph, setNsrRangeMargin]}
              ></EmptyChart>
            )}
          </Loader>
        </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </>
  );
};

export default ChartBase;
