import { Form, Menu, MenuProps, Modal, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchAPI } from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import CommonButton from "../../common/input/CommonButton";
import Label from "../../common/input/Label";
import "./EditMarketOffering.css";
import ErrorMessage from "../../common/input/ErrorMessage";
import SuccessMessage from "../../common/input/SuccessMessage";
import TextField from "../../common/input/TextField";
import LeadsDropdown from "../offering/LeadsDropdown";
import OfferingDropdown from "./OfferingDropdown";
import DropDown from "../../common/input/DropDown";
import { addMarketOfferingEnums, inputValidationError, validateResponse, AddNewMarketOfferingEnums } from "../../../shared/enums/userManagementEnum";
import UnEditableDropDown from "../../common/input/UnEditableDropdown";
import UsersDropdown from "../offering/UsersDropdown";

const { Title, Text } = Typography;

const EditMarketOffering = ({
  editMarketOfferingModalOpen,
  setEditMarketOfferingModalOpen,
  marketOfferingData,
  getMarketOfferingData,
  setCurrentPage,
}: any) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [validateLeadandDelegate, setValidateLeadandDelegate] = useState(false);
  const [offering_lead_id, setOffering_lead_id] = useState<string>("");
  const [offering_delegate_id, setOffering_delegate_id] = useState<string>("");
  const [valueChanged, setValueChanged] = useState(false);
  const [leadersData, setLeadersData] = useState<any>([]);
  const [validated, setValidated] = useState<boolean>(false);
  const [offeringData, setOfferingData] = useState<any>([]);
  const [emThresholdData, setemThresholdData] = useState<any>([]);
  const [nsrValidation, setNsrValidation] = useState<boolean>(false);
  const [bulgedata, setbulgedata] = useState<any>([{"key":"Bulge Ratio", "label":"Bulge Ratio"}, {"key":"Span of Control", "label":"Span of Control"}]);
  const [bulgeOverallTextdisable, setBulgeOverallTextdisable] = useState<boolean>(true);
  const [validateBulge, setValidateBulge] = useState<boolean>(true);
  const [marketOfferingEditDetails, setMarketOfferingEditDetails] =
    useState<any>({
      mo_name: "",
      offering: "",
      mo_code: "",
      mo_lead_user: "",
      mo_delegate_user: "",
      offering_Lead: "",
      emThreshold: "",
      service_excellence_lead_user: "",
      delivery_excellence_lead_user: "",
      revenue_range_xs: 0,
      revenue_range_s: 0,
      market_Pricing_Guidance: "",
      revenue_range_m: 0,
      revenue_range_l: 0,
      bulge_soc: "",
      bulge_soc_overall: null
    });
  useEffect(() => {
    setValidateLeadandDelegate(false);
    setMarketOfferingEditDetails({
      emThreshold: marketOfferingData?.engagement_type?.engagement_type || "",
      mo_code: marketOfferingData?.mo_code || "",
      offering: marketOfferingData?.offering?.offering_name || "",
      mo_name: marketOfferingData?.mo_name || "",
      mo_lead_user: marketOfferingData?.mo_lead_user?.preferred
        ? marketOfferingData?.mo_lead_user?.preferred : "",
      mo_delegate_user: marketOfferingData?.mo_delegate_user?.preferred
        ? marketOfferingData?.mo_delegate_user?.preferred : "",
      offering_Lead:
        marketOfferingData?.offering_portfolio?.offering_Lead || "",
      service_excellence_lead_user: marketOfferingData
        ?.service_excellence_lead_user?.preferred
        ? marketOfferingData?.service_excellence_lead_user?.preferred : "",
      delivery_excellence_lead_user: marketOfferingData
        ?.delivery_excellence_lead_user?.preferred
        ? marketOfferingData?.delivery_excellence_lead_user?.preferred : "",
      target_RPH: marketOfferingData?.offering_lead_user?.target_RPH || "",
      target_Commercial_Structure:
        marketOfferingData?.target_Commercial_Structure || "",
      revenue_range_xs: marketOfferingData?.revenue_range_xs,
      revenue_range_s: marketOfferingData?.revenue_range_s || null,
      market_Pricing_Guidance:
        marketOfferingData?.market_Pricing_Guidance || "",
      revenue_range_m: marketOfferingData?.revenue_range_m || null,
      revenue_range_l: marketOfferingData?.revenue_range_l || null,
      bulge_soc: marketOfferingData?.bulge_soc,
      bulge_soc_overall: marketOfferingData?.bulge_soc_overall
    });
    callApi();
    callOfferingApi();
    callEMThresholdApi();
    if(marketOfferingData != null && marketOfferingData.mo_lead_user != null && marketOfferingData.mo_delegate_user != null){
      if(marketOfferingData.mo_lead_user.id == marketOfferingData.mo_delegate_user.id){
        setValidateLeadandDelegate(true);
      }
    }
    setOffering_lead_id(marketOfferingData!= null ? marketOfferingData.mo_lead_user != null ? marketOfferingData.mo_lead_user.id : "":"");
    setOffering_delegate_id(marketOfferingData!= null ? marketOfferingData.mo_delegate_user != null ? marketOfferingData.mo_delegate_user.id : "":"");
    if(marketOfferingData?.bulge_soc){
      setBulgeOverallTextdisable(false);
    }
  }, [editMarketOfferingModalOpen]);

  useEffect(() => {
    let validateBulgeValue = true;
    if(marketOfferingEditDetails.bulge_soc != "" && marketOfferingEditDetails.bulge_soc){
        if(marketOfferingEditDetails.bulge_soc_overall){
          validateBulgeValue = true;
        }
        else{
          validateBulgeValue = false;
        }
    }
    if (
			marketOfferingEditDetails.mo_name.trim() !== "" &&
			marketOfferingEditDetails.mo_name.trim().length >=2 &&
			marketOfferingEditDetails.revenue_range_xs !== "" &&
			marketOfferingEditDetails.revenue_range_s !== "" &&
			marketOfferingEditDetails.revenue_range_l !== "" &&
      offering_lead_id != offering_delegate_id
      && validateBulgeValue == true
		) {
			setValidated(true);
		} else {
			setValidated(false);
		}
  }, [marketOfferingEditDetails]);

  const options = {
    headers: {}
  }
  const callEMThresholdApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.emThreshold}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          id: item.id,
          key: item.engagement_type,
          label: item.engagement_type,
        }));
        setemThresholdData(datas);
      });
  };

  const setOfferingLeadVal = (val: string) =>{
    setOffering_lead_id(val);
    setValidateLeadandDelegate(val == offering_delegate_id ? true: false);
  }

  const setOfferingDelegateVal = (val: string) =>{
    setOffering_delegate_id(val);
    setValidateLeadandDelegate(val == offering_lead_id ? true : false);
  }

  const setDeliveryExcellenceLeaderVal = (val: string) =>{ }

  const setSalesExcellenceLeadVal =(val: string) => { }

  const callOfferingApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.offering}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.offering_code,
          text: item.label,
        }));
        setOfferingData(datas);
      });
  };

  const callApi = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}${Endpoints.user}`, options)
      .then((response: any) => response.json())
      .then((d: any) => {
        const { data } = d;
        const datas = data.map((item: any) => ({
          value: item.id,
          text: item.preferred,
        }));
        setLeadersData(datas);
      });
  };

  const handleOk = () => {
    if ((parseInt(marketOfferingEditDetails.revenue_range_xs) >= parseInt(marketOfferingEditDetails.revenue_range_s)) ||
    parseInt(marketOfferingEditDetails.revenue_range_s) >= parseInt(marketOfferingEditDetails.revenue_range_l)) {
      setNsrValidation(true);
    }
    else{
      updateMarketOfferingDetails();
      // setCurrentPage(1);
      setValueChanged(false);
      setError("");
      setEditMarketOfferingModalOpen(false);
      setLeadersData("");
    }
  };

  useEffect(() =>{
    if (marketOfferingEditDetails.revenue_range_xs != "" && marketOfferingEditDetails.revenue_range_s != "" && marketOfferingEditDetails.revenue_range_l) {
      if (!((parseInt(marketOfferingEditDetails.revenue_range_xs) >= parseInt(marketOfferingEditDetails.revenue_range_s)) ||
        parseInt(marketOfferingEditDetails.revenue_range_s) >= parseInt(marketOfferingEditDetails.revenue_range_l))) {
        setNsrValidation(false);
      }
    }
  },[marketOfferingEditDetails.revenue_range_xs, marketOfferingEditDetails.revenue_range_s, marketOfferingEditDetails.revenue_range_l])

  const handleCancel = () => {
    setError("");
    setLeadersData("");
    setValueChanged(false);
    setEditMarketOfferingModalOpen(false);
  };

  const handleTextInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[A-Za-z &,.-]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMarketOfferingEditDetails({
        ...marketOfferingEditDetails,
        [e.target.name]: e.target.value,
      });
      setValueChanged(true);
    }
  };

  const handleNumberInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const re = /^[0-9]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMarketOfferingEditDetails({
        ...marketOfferingEditDetails,
        [e.target.name]: e.target.value,
      });
      setValueChanged(true);
    }
  };

  const setemThreshold = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      emThreshold: val,
    });
  };

  const handleEMDropdownChange: MenuProps["onClick"] = (e) => {
    setemThreshold(e.keyPath[0]);
  };
  const emMenuItems = (
    <Menu onClick={handleEMDropdownChange} items={emThresholdData} />
  );

  const setOffering = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      offering: val,
    });
  };
  const setSalesExcellenceLead = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      service_excellence_lead_user: val,
    });
  };

  const setDeliveryExcellenceLead = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      delivery_excellence_lead_user: val,
    });
  };
  const setmarketOfferingLead = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      mo_lead_user: val,
    });
  };

  const setmarketOfferingDelegate = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      mo_delegate_user: val,
    });
  };
  const getBody = (
    offering: any,
    mo_delegate: any,
    mo_lead: any,
    delivery: any,
    service: any,
    emthreshold: any
  ) => {
    if (mo_delegate == "") {
      return {
        mo_name: marketOfferingEditDetails?.mo_name.trim(),
        offering_code: offering,
        engagement_type: emthreshold,
        mo_lead: mo_lead,
        revenue_range_xs: parseInt(marketOfferingEditDetails?.revenue_range_xs),
        revenue_range_s: parseInt(marketOfferingEditDetails?.revenue_range_s),
        revenue_range_m: parseInt(marketOfferingEditDetails?.revenue_range_l),
        delivery_excellence_lead: delivery,
        service_excellence_lead: service,
        bulge_soc: marketOfferingEditDetails?.bulge_soc,
        bulge_soc_overall: parseFloat(marketOfferingEditDetails?.bulge_soc_overall)
      };
    } else
      return {
        mo_name: marketOfferingEditDetails?.mo_name.trim(),
        offering_code: offering,
        engagement_type: emthreshold,
        mo_lead: mo_lead,
        revenue_range_xs: parseInt(marketOfferingEditDetails?.revenue_range_xs),
        revenue_range_s: parseInt(marketOfferingEditDetails?.revenue_range_s),
        revenue_range_m: parseInt(marketOfferingEditDetails?.revenue_range_l),
        delivery_excellence_lead: delivery,
        service_excellence_lead: service,
        mo_delegate: mo_delegate,
        bulge_soc: marketOfferingEditDetails?.bulge_soc,
        bulge_soc_overall: parseFloat(marketOfferingEditDetails?.bulge_soc_overall)
      };
  };

  const updateMarketOfferingDetails = async () => {
    const offering_code = offeringData.find((item: any) => {
      return item.text == marketOfferingEditDetails.offering;
    });
    let delegate: any = "";
    const val = leadersData?.find((item: any) => {
      return item.text == marketOfferingEditDetails?.mo_delegate_user;
    });
    delegate = val?.value || "";
    const mo_lead = leadersData.find((item: any) => {
      return item.text == marketOfferingEditDetails.mo_lead_user;
    });
    const delivery_excellence_lead = leadersData.find((item: any) => {
      return (
        item.text == marketOfferingEditDetails.delivery_excellence_lead_user
      );
    });
    const service_excellence_lead = leadersData.find((item: any) => {
      return (
        item.text == marketOfferingEditDetails.service_excellence_lead_user
      );
    });
    const engagement_type = emThresholdData.find((item: any) => {
      return item.key == marketOfferingEditDetails.emThreshold;
    });

    try {
      const options = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        queryParam: "mo_code=" + marketOfferingData?.mo_code,
        body: JSON.stringify(
          getBody(
            offering_code?.value,
            delegate,
            mo_lead?.value,
            delivery_excellence_lead?.value,
            service_excellence_lead?.value,
            engagement_type?.id
          )
        ),
      };

      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.marketOffering}`,
        options
      );
      if (!data?.errMsg) {
        if(data.responseData.message.includes(`${validateResponse.sameName}`)){
          ErrorMessage(`${AddNewMarketOfferingEnums.MoNameExists}`);
        }
        else {
          SuccessMessage(`Market Offering ${marketOfferingEditDetails.mo_name} updated successfully.`);
          getMarketOfferingData();
        }
      } else {
        ErrorMessage("Something went wrong. Please try again later");
      }
    } catch (err) {
      ErrorMessage("Something went wrong. Please try again later");
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", escFunction, false);

    return () => {
      document.removeEventListener("keyup", escFunction, false);
    };
  }, [editMarketOfferingModalOpen]);

  const escFunction = (event: any) => {
    if (event.key === "Escape") {
      handleCancel();
    }
  };

  const handleBulgeSocChange: MenuProps["onClick"] = (e) => {
    setBulgeSoc(e.keyPath[0]);
    setBulgeOverallTextdisable(false);
  };

  const bulge_soc_MenuItems= (
    <Menu onClick={handleBulgeSocChange} items={bulgedata} />
  );

  const setBulgeSoc = (val: any) => {
    setValueChanged(true);
    setMarketOfferingEditDetails({
      ...marketOfferingEditDetails,
      bulge_soc: val,
    });
  };

  const handleDecimalNumberInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const regex = /^[0-9]+$/;
    if(e.target.value != "."){
      let splitted = e.target.value.split('');
      let isValid = true;
      for(let n of splitted){
        isValid = regex.test(n) || n == ".";
        if (!isValid) {
          break;
        }
      }
      if (isValid) {
        setMarketOfferingEditDetails({
          ...marketOfferingEditDetails,
          [e.target.name]: e.target.value,
        });
        setValueChanged(true);
      }
    }
  };

  return (
		<>
			<Modal
				open={editMarketOfferingModalOpen}
				closable={false}
				footer={null}
				width={"56.5rem"}
				centered
			>
				<Form
					onFinish={handleOk}
					form={form}
					layout="vertical"
					className="edit-offering-form"
					fields={[
						{
							name: ["mo_name"],
							value: marketOfferingEditDetails?.mo_name,
						},
					]}
				>
					<Title level={5}>Edit Market Offering</Title>
					<Text>
						This will help you to edit a market offering in the framework.
					</Text>
					<div className="admin-offering-input-main">
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
              <UnEditableDropDown
									placeholder={addMarketOfferingEnums.MOCodeLabel}
									value={marketOfferingEditDetails.mo_code || "--"}
								/>
								{/* <Text>MO Code</Text>
								<TextField
									name="mo_code"
									onChange={handleTextInputChange}
									value={marketOfferingEditDetails.mo_code}
									className="offering-textfield"
									disabled={true}
								/> */}
							</div>
							<div className="market-offering-input">
								<Form.Item
									label="Market Offering Name *"
									name="mo_name"
									// rules={[
									// 	{
									// 		whitespace: true,
									// 		message: "Display Name cannot be empty.",
									// 	},
									// 	{
									// 		validator: (_, value) =>
									// 			value.trim().length >= 2
									// 				? Promise.resolve()
									// 				: Promise.reject(
									// 						new Error(inputValidationError.min2Character)
									// 				  ),
									// 	},
									// 	{
									// 		max: 500,
									// 		message: inputValidationError.max500Characters,
									// 		type: "string",
									// 	},
									// ]}
									// key={marketOfferingEditDetails?.mo_code}
								>
									<TextField
										name="mo_name"
										onChange={handleTextInputChange}
										value={marketOfferingEditDetails.mo_name}
										className="offering-textfield"
                    maxLength={100}
										// disabled={true}
									/>
								</Form.Item>
							</div>
							<div className="market-offering-input">
								<Text>Market Offering Lead *</Text>
								<div className="mo_admin-dropdown">
									<LeadsDropdown
										setInitialVal={setmarketOfferingLead}
										style={{ width: "100%" }}
										initialVal={marketOfferingEditDetails.mo_lead_user}
                    setSelectedVal={setOfferingLeadVal}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>Sales Excellence Leader *</Text>
								<div className="mo_admin-dropdown">
									<UsersDropdown
										setInitialVal={setSalesExcellenceLead}
										style={{ width: "100%" }}
										initialVal={
											marketOfferingEditDetails.service_excellence_lead_user
										}
                    setSelectedVal={setSalesExcellenceLeadVal}
									/>
								</div>
							</div>
							<div className="market-offering-input">
								<Text>Delivery Excellence Leader *</Text>
								<div className="mo_admin-dropdown">
									<UsersDropdown
										setInitialVal={setDeliveryExcellenceLead}
										style={{ width: "100%" }}
										initialVal={
											marketOfferingEditDetails.delivery_excellence_lead_user
										}
                    setSelectedVal={setDeliveryExcellenceLeaderVal}
									/>
								</div>
							</div>
							<div className="market-offering-input">
								<Text>Offering *</Text>
								<div className="mo_admin-dropdown">
									<OfferingDropdown
										setInitialVal={setOffering}
										style={{ width: "100%" }}
										initialVal={marketOfferingEditDetails.offering}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>EM Threshold *</Text>
								<div className="mo_admin-add-dropdown">
									<DropDown
										menu={emMenuItems}
										value={
											marketOfferingEditDetails.emThreshold ||
											"Select Threshold"
										}
										placeholder={""}
									/>
								</div>
							</div>
							<div className="market-offering-input">
								<Text>Market Offering Delegate</Text>
								<div className="mo_admin-dropdown">
									<LeadsDropdown
										setInitialVal={setmarketOfferingDelegate}
										style={{ width: "100%" }}
										initialVal={marketOfferingEditDetails.mo_delegate_user}
                    setSelectedVal={setOfferingDelegateVal}
									/>
                  {validateLeadandDelegate == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Market Offering Lead and Delegate cannot be same."} />
                )}
								</div>
							</div>
						</div>

            <div className="admin-market-offering-row">
							<div className="market-offering-input">
								<Text>Bulge Ratio/Span of Control</Text>
								<div className="mo_admin-add-dropdown">
                <DropDown
										menu={bulge_soc_MenuItems}
										value={
											marketOfferingEditDetails.bulge_soc || "Select"
										}
										placeholder={""}
									/>
								</div>
							</div>
							<div className="market-offering-input">
              <Text>Bulge/SOC Overall</Text>
                <TextField
										name="bulge_soc_overall"
										onChange={handleDecimalNumberInputChange}
										value={marketOfferingEditDetails.bulge_soc_overall}
										className="offering-textfield"
                    maxLength={8}
										disabled={bulgeOverallTextdisable}
									/>
							</div>
						</div>

						<div className="admin-market-offering-row">
							<div className="market-offering-text-heading-2">
								Tool Setting - NSR Range
							</div>
						</div>
						<div className="admin-market-offering-row">
							<div className="market-offering-input-nsr">
								<div className="nsr-input">
									<Text>Small *</Text>
									<TextField
										name="revenue_range_xs"
										onChange={handleNumberInputChange}
										value={marketOfferingEditDetails.revenue_range_xs}
										className="offering-textfield"
                    maxLength={8}
										// disabled={true}
									/>
								</div>
								<div className="nsr-input-text">
									<Text>to</Text>
								</div>
								<div className="nsr-input-to">
									<TextField
										name="revenue_range_s"
										onChange={handleNumberInputChange}
										value={marketOfferingEditDetails.revenue_range_s}
                    maxLength={8}
										className="offering-textfield"
										//  // disabled={true}
									/>
								</div>
							</div>
						</div>
						<div className="admin-market-offering-textarea">
							<div className="market-offering-input-nsr">
								<div className="nsr-input">
									<Text>Medium *</Text>
									<TextField
										name="revenue_range_s"
										onChange={handleNumberInputChange}
										value={marketOfferingEditDetails.revenue_range_s}
										className="offering-textfield"
										disabled={true}
									/>
								</div>
								<div className="nsr-input-text">
									<Text>to</Text>
								</div>
								<div className="nsr-input-to">
									<TextField
										name="revenue_range_l"
										onChange={handleNumberInputChange}
										value={marketOfferingEditDetails.revenue_range_l}
										className="offering-textfield"
                    maxLength={8}
										// disabled={true}
									/>
								</div>
								<div className="nsr-input">
									<Text>Large *</Text>
									<TextField
										name="revenue_range_l"
										onChange={handleNumberInputChange}
										value={marketOfferingEditDetails.revenue_range_l}
										className="offering-textfield"
										disabled={true}
									/>
								</div>
								<div className="nsr-input-text-for-large">
									<Text>and more</Text>
								</div>
							</div>
              {nsrValidation == true && (
                  <Label style={{ color: "#FF0000" }}
              placeholder={"Final NSR Range must be greater than Start NSR Range."} />
                )}
						</div>
					</div>
					<div className="admin-offering-buttons">
						<CommonButton
							value="Cancel"
							onClick={handleCancel}
							color="#FFFFFF"
							fontColor="rgba(0, 0, 0, 0.85)"
							border="1px solid #D9D9D9"
						/>
						<CommonButton
							value="Update"
							onClick={handleOk}
							disabled={!validated || !valueChanged}
							className="save-button"
						/>
					</div>
				</Form>
			</Modal>
		</>
	);
};

export default EditMarketOffering;
