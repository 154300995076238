import { Pagination, Form, Table, Button, MenuProps, Menu } from "antd";
import { EditOutlined, DeleteOutlined, FilterFilled } from "@ant-design/icons";
import TextField from "../../common/input/TextField";
import React, { useEffect, useState } from "react";
import {
  fetchAPI,
  getErrorResponseStatus,
} from "../../../shared/custom-hooks/fetchAPI";
import { Endpoints } from "../../../shared/enums/endpoints";
import ErrorPage from "../../common/errorPage/ErrorPage";
import CommonButton from "../../common/input/CommonButton";
import HighlightTable from "../../common/highlighter/HighlightTable";
import "./UserManagement.css";
import AddUser from "./AddUser";
import EditRole from "./EditRole";
import DeleteUser from "./DeleteUser";
import DropDown from "../../common/input/DropDown";

interface iUserManagement {
  token?: string;
}

const UserManagement = ({token}: iUserManagement) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [userTableData, setUserTableData] = useState([]);
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [editRoleModalOpen, setEditRoleModalOpen] = useState(false);
  const [toEditData, setToEditData] = useState(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [deleteUser, setDeleteUser] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [roleMenuItems, setRoleMenuItems] = useState([]);
  const [roleItemsDetails, setRoleItemDetails] = useState<any>([]);
  const [filterScope, setFilterScope] = useState<string>("All");
  const [searchValueChanged, setSearchValueChanged] = useState(false);
  const [analyticLoaded, setAnalyticLoaded] = useState(false);

  useEffect(() =>{
    if(sessionStorage.getItem("userManagementCall") == "false"){
      window.analyticsDataLayer = {
        pageInfo:{
          pageName:"User Management",
          userID: sessionStorage.getItem('userName'),
          applicationName: "Strategic Pricing Portal",
          userType: sessionStorage.getItem("userType"),
        }
      };
      if(typeof window._satellite !== 'undefined'){
        //window._satellite.track("pageView");
      }
      sessionStorage.setItem("userManagementCall", "true");
      sessionStorage.setItem("dashboardCall", "false");
      sessionStorage.setItem("adminCall", "true");
    }
  })

  useEffect(() => {
    getUserData();
    //getallroles();
  }, []);
  const handleEdit = (val: any) => {
    //here use val.id for getting data
    //getallroles();
    setEditRoleModalOpen(true);
    setToEditData(val);
  };
  const handleDelete = (val: any) => {
    //here use val.id for getting data
    setDeleteUser(val);
    setDeleteModalOpen(true);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "preferred",
    },
    {
      title: "Email",
      dataIndex: "email_id",
    },
    {
      title: "Scope",
      dataIndex: ["role", "display_name"],
    },
    {
      title: "Action",
      width: 100,
      dataIndex: "action",
      render: (_: any, record: any) => (
        <div className="admin-action-area">
          <a onClick={() => handleEdit(record)}>
            <EditOutlined style={{ fontSize: "18px", color: "#007CB0" }} />
          </a>
          <a onClick={() => handleDelete(record)}>
            <DeleteOutlined style={{ fontSize: "18px", color: "#DA291C" }} />
          </a>
        </div>
      ),
    },
  ];
  const getallroles = async () => {
    //setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.roles}`,
        {
          method: "GET",
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setRoleMenuItems(
            data?.responseData.data.map((roleData: any) => {
              return {
                key: roleData.display_name,
                label: roleData.display_name,
              };
            })
          );
          setRoleItemDetails(data?.responseData.data || []);
        } else {
          setRoleMenuItems([]);
          setRoleItemDetails([]);
        }
      } else {
        console.log("Res Status", data?.responseStatus);
        setError("Error");
      }
    } catch (err) {
      console.log(err);
      setError("Error");
    }
    //setLoading(false);
  };
  const getFilteredDetail = async (
    currentPage: number = 1,
    filterValue: string
  ) => {
    setLoading(true);
    let tmp_role_id = roleItemsDetails.filter((item: any) => {
      if (`${item.display_name}` === filterValue) return item;
    });
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        {
          method: "GET",
          queryParam: `role_id=${
            tmp_role_id[0].id
          }&page=${currentPage}&contains=${searchValue.trim()}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setUserTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      console.log("Res Status", err);
      setError("error");
    }

  };
  const refreshTable = async (
    currentPage: number = 1,
    searchparam: string = searchValue
  ) => {
    setUserTableData([]);
    setLoading(true);
    try {
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}&contains=${searchparam.trim()}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setUserTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      setError("Error");
    }
    //setLoading(false);
  };

  const getUserData = async (currentPage: number = 1) => {
    setLoading(true);
    try {
      setUserTableData([]);
      const data: any = await fetchAPI(
        `${process.env.REACT_APP_BASE_URL}${Endpoints.user}`,
        {
          method: "GET",
          queryParam: `page=${currentPage}&contains=${searchValue.trim()}`,
          headers: {}
        }
      );
      if (!data?.errMsg) {
        if (Object.keys(data?.responseData?.data || []).length) {
          setUserTableData(data?.responseData.data);
          if (currentPage == 1) {
            setTotalPage(data?.responseData?.message.total_records);
          }
        } else {
          setUserTableData([]);
        }
        setLoading(false);
      } else {
        setLoading(false);
        console.log("Res Status", data?.responseStatus);
        setError(data.responseStatus);
      }
    } catch (err) {
      setLoading(false);
      setError("Error");
    }
    
  };
  const handlePagination = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    getUserData(pageNumber);
  };

  useEffect(() =>{
    if(searchValue === "" && loading === false)
    {
      handleSearchButton();
    }
  }, [searchValue])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const re = /^[0-9A-Za-z@. ]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setSearchValue(e.target.value);
      setSearchValueChanged(true);
    }
  };

  const handleSearchButton = () => {
    if(loading==false)
    {
    setCurrentPage(1);
    if (filterScope === "All") {
      getUserData();
    } else {
      getFilteredDetail(1, filterScope);
    }
  }

  };
  const handleScopeChange: MenuProps["onClick"] = (e) => {
    setFilterScope(e.keyPath[0]);
    if (e.keyPath[0] === "All") {
      getUserData();
    } else {
      getFilteredDetail(1, e.keyPath[0]);
    }
  };
  const filterScopeDropdownMenu = (
    <Menu
      onClick={handleScopeChange}
      items={[
        {
          label: "All",
          key: "All",
        },
        ...roleMenuItems,
      ]}
    />
  );
  
  return (
    <div>
      <Form onFinish={handleSearchButton}>
      {error === "" ? (
        <>
          {addUserModalOpen && (
            <AddUser
              addUserModalOpen={addUserModalOpen}
              setAddUserModalOpen={setAddUserModalOpen}
              setSearchValue={setSearchValue}
              setFilterScope={setFilterScope}
              refreshTable={refreshTable}
              searchValue = {searchValue}
              token = {token}
            />
          )}
          {editRoleModalOpen && (<EditRole
            editRoleModalOpen={editRoleModalOpen}
            setEditRoleModalOpen={setEditRoleModalOpen}
            userData={toEditData}
            getUserData={getUserData}
            currentPage={currentPage}
            setSearchValue={setSearchValue}
            setFilterScope={setFilterScope}
            refreshTable={refreshTable}
            searchValue = {searchValue}
          />)}
          <DeleteUser
            deleteModalOpen={deleteModalOpen}
            setDeleteModalOpen={setDeleteModalOpen}
            user={deleteUser}
            setUser={setDeleteUser}
            getUserData={getUserData}
            currentPage={currentPage}
            setSearchValue={setSearchValue}
            setFilterScope={setFilterScope}
            refreshTable={refreshTable}
            searchValue = {searchValue}
          />
          <>
            <div className="user-main">
              <div className="label">
                <div className="search">
                  <TextField
                    onChange={handleSearchChange}
                    value={searchValue}
                    name="first_name"
                    placeholder="Search by Name, Email and Scope."
                    className="mo-admin-header-search-textfield textfield"
                    style={{ width: '30em' }}
                  />
                  <Button id="btnUserSearch"
                    style={{ background: "#007CB0", color: "#FFFFFF" }}
                    onClick={handleSearchButton}
                    htmlType="submit"
                    disabled={!searchValueChanged}
                  >
                    Search
                  </Button>
                </div>
                {/* <div className="filter">
                  <div className="filter-span">
                    <FilterFilled
                      style={{ color: "#007CB0", fontSize: "19px" }}
                    />{" "}
                    <span className="filter-label">Roles</span>
                  </div>
                  <DropDown
                    value={filterScope || "All"}
                    menu={filterScopeDropdownMenu}
                    placeholder=""
                    loading={loading}
                    className="scope-filter-dropdown"
                  />
                </div> */}
              </div>
              <CommonButton
                onClick={() => setAddUserModalOpen(true)}
                value="Add User"
              ></CommonButton>
            </div>
            <>
            <HighlightTable columns={columns} pagination={false}
                loading={loading} tableData={userTableData} searchValue={searchValue} startPosition={4}/>
              {/* <Table id="tblData"
                columns={columns}
                dataSource={userTableData}
                pagination={false}
                loading={loading}
              /> */}
              {userTableData.length > 0 ? (
                <Pagination
                  className="user-paginantion"
                  onChange={handlePagination}
                  defaultCurrent={currentPage}
                  current={currentPage}
                  total={totalPage}
                />
              ) : (
                ""
              )}
            </>
          </>
        </>
      ) : (
        <ErrorPage responseStatus={getErrorResponseStatus(error)} />
      )}
    </Form>
    </div>
    
  );
};

export default UserManagement;
